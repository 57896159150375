import { EXHIBITION_STATES } from '@zwirner/design-system'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { newYorkTimeZone } from '@/common/constants/timezone'

dayjs.extend(isBetween)
dayjs.extend(timezone)
dayjs.extend(utc)

type Exhibition = { startDate: string; endDate: string; locations: any[] }

export const isExhibitionOpen = (data: Exhibition) => {
  const { startDate, endDate, locations } = data
  const currentTimeInNY = dayjs.tz(dayjs(), newYorkTimeZone)

  const startDateInNY = dayjs.tz(startDate, newYorkTimeZone).startOf('day')
  const endDateInNY = dayjs.tz(endDate, newYorkTimeZone).endOf('day')

  return (locations?.some((loc: { timezone?: string }) => {
    const { timezone } = loc
    const timeZoneLoc = timezone ?? newYorkTimeZone
    const startDateLoc = dayjs.tz(startDate, timeZoneLoc).startOf('day')
    const endDateLoc = dayjs.tz(endDate, timeZoneLoc).endOf('day')
    const currentTimeInTZ = dayjs.tz(dayjs(), timeZoneLoc)

    if (startDateLoc.isValid() && endDateLoc.isValid()) {
      return currentTimeInTZ.isBetween(startDateLoc, endDateLoc, null, '[]')
    }
    return false
  }) ??
    (startDateInNY.isValid() && endDateInNY.isValid()))
    ? currentTimeInNY.isBetween(startDateInNY, endDateInNY, null, '[]')
    : false
}

export const isExhibitionUpcoming = (exhibition: { startDate: string }) => {
  const { startDate } = exhibition
  const currentTimeInNY = dayjs.tz(dayjs(), newYorkTimeZone)
  const startDateInNY = dayjs.tz(startDate, newYorkTimeZone).startOf('day')

  return startDateInNY.isValid() && currentTimeInNY.isBefore(startDateInNY)
}

export const isExhibitionPast = (exhibition: { endDate: string }) => {
  const { endDate } = exhibition
  const currentTimeInNY = dayjs.tz(dayjs(), newYorkTimeZone)
  const endDateInNY = dayjs.tz(endDate, newYorkTimeZone).endOf('day')

  return endDateInNY.isValid() && currentTimeInNY.isAfter(endDateInNY)
}
export const getExhibitionState = (exhibition: Exhibition) => {
  const isOpen = isExhibitionOpen({
    startDate: exhibition.startDate,
    endDate: exhibition.endDate,
    locations: exhibition.locations,
  })
  const isUpcoming = isExhibitionUpcoming(exhibition)
  const isPast = isExhibitionPast(exhibition)

  if (isOpen) return EXHIBITION_STATES.OPEN
  if (isUpcoming) return EXHIBITION_STATES.PRELAUNCH
  if (isPast) return EXHIBITION_STATES.POSTLAUNCH
}
