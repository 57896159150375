import { groq } from 'next-sanity'
import { z } from 'zod'

import { BasicContentSchema } from '../components/portableText/basicContent'
import { basicContentWithLinks } from '../components/portableText/basicContentWithLinks'
import { filterSectionsFields, FilterSectionsSchema } from '../popups/allCampaigns'

export const allEmailGates = groq`
*[_type == "emailGate"]{
  _id,
  cta,
  description[]{
    ${basicContentWithLinks}
  },
  expirationDays,
  filterSections[]{
    ${filterSectionsFields}
  },
}|order(orderRank)
`

const EmailGateSchema = z.object({
  _id: z.string(),
  expirationDays: z.number(),
  description: z.array(BasicContentSchema).nullable(),
  cta: z.string().nullable(),
  filterSections: FilterSectionsSchema.nullable(),
})

export const AllEmailGatesScheme = z.array(EmailGateSchema)

export type EmailGateType = z.infer<typeof EmailGateSchema>
export type AllEmailGatesType = z.infer<typeof AllEmailGatesScheme>
