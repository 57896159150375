import { FORM_MODAL_TYPES } from '@zwirner/design-system'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import type { ComponentProps, Ref } from 'react'
import { createPortal } from 'react-dom'

import { DzFormModal } from '../wrappers/DzFormModalWrapper'

const RecaptchaNode = dynamic(() => import('@/components/forms/recaptchaNode'), {
  ssr: false,
})

export const NewsletterFormModal = (
  props: Omit<ComponentProps<typeof DzFormModal>, 'type'> & {
    recaptchaRef: Ref<HTMLFormElement | undefined>
    usePortalForRecaptcha?: boolean
  }
) => {
  const router = useRouter()
  const { recaptchaRef, usePortalForRecaptcha, ...formModalProps } = props
  const Recaptcha = <RecaptchaNode recaptchaRef={recaptchaRef} />

  return (
    <DzFormModal
      {...formModalProps}
      type={FORM_MODAL_TYPES.NEWSLETTER}
      isDz30={router.asPath.includes('30years')}
      recaptchaNode={usePortalForRecaptcha ? createPortal(Recaptcha, document.body) : Recaptcha}
    />
  )
}
