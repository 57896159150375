import { useEffect } from 'react'

import {
  landingPageSessionKey,
  type LandingPageState,
  useLandingPageStore,
} from '@/store/landingPageStore'

const landingPageTTL = 30 * 60 * 1000

type LandingPageSession = {
  state: LandingPageState
}

export const useLandingPage = () => {
  const { setLandingPageURL } = useLandingPageStore()
  useEffect(() => {
    const now = new Date()
    const currentPath = `${window.location.pathname}${window.location.search}${window.location.hash}`
    const landingPageState: LandingPageSession = JSON.parse(
      sessionStorage.getItem(landingPageSessionKey) || '{}'
    )
    const { url, expiry } = landingPageState?.state?.landingPageURL ?? {}

    let newURL = undefined
    let newExpiry = 0

    if ((url == undefined && expiry == 0) || expiry < now.getTime()) {
      // if no lannding page is defined, or the landing page is expired,
      // set the current URL as the landing page and set 30 minute expiry
      newURL = currentPath
      newExpiry = now.getTime() + landingPageTTL
    } else if (url !== undefined && currentPath !== url && expiry > now.getTime()) {
      //subsequent vists keeps the same landing page, and resets the expiry to
      // keep it for another 30 minutes
      newURL = url
      newExpiry = now.getTime() + landingPageTTL
    }
    setLandingPageURL({
      url: newURL,
      expiry: newExpiry,
    })
  }, [setLandingPageURL])
}
