import dayjs from 'dayjs'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type UserEmailState = { expiresOn?: Date }

type UserEmailStateStore = UserEmailState & {
  setUserEmailCollectedExpirationInDays: (days: number) => void
}

const INITIAL_STATE: UserEmailState = {}

const storeName = 'userEmailState'

const useUserEmailStore = create<UserEmailStateStore>()(
  persist(
    (set) => ({
      ...INITIAL_STATE,
      setUserEmailCollectedExpirationInDays: (days) =>
        set({ expiresOn: dayjs().add(days, 'days').toDate() }),
    }),
    { name: storeName, storage: createJSONStorage(() => localStorage) }
  )
)

export const { setUserEmailCollectedExpirationInDays } = useUserEmailStore.getState()

export const useIsEmailExpired = (): boolean => {
  const { expiresOn } = useUserEmailStore()
  if (!expiresOn) return true
  return dayjs().isAfter(expiresOn)
}
