import Head from 'next/head'
import { useRouter } from 'next/router'
import {
  ArticleJsonLd,
  BreadcrumbJsonLd,
  DefaultSeo,
  NextSeo,
  SiteLinksSearchBoxJsonLd,
} from 'next-seo'

import type { GeneralSEOSchemaType } from '@/sanity/queries/components/seo/generalSEO'
import type { PageSEOFieldsExtendedType } from '@/sanity/queries/components/seo/pageSEOFields'
import { JsonLDSchemaTypeSchema } from '@/sanity/queries/components/seo/pageSEOFields'

import {
  articleJSONLDMapper,
  breadcrumbsJSONLDMapper,
  defaultSeoMapper,
  perPageSeoMapper,
  searchBoxJSONLDMapper,
} from './mappers'

type JSONLDSchema = NonNullable<PageSEOFieldsExtendedType['jsonLD']>

const JsonLDSchemaTypes = {
  [JsonLDSchemaTypeSchema.enum.article]: (data: JSONLDSchema, url: string) => {
    const { article } = data
    return <ArticleJsonLd {...articleJSONLDMapper(article, url)} useAppDir={false} />
  },
  [JsonLDSchemaTypeSchema.enum.blog]: (data: JSONLDSchema, url: string) => {
    const { article } = data
    return <ArticleJsonLd {...articleJSONLDMapper(article, url)} useAppDir={false} />
  },
  [JsonLDSchemaTypeSchema.enum.breadcrumb]: (data: JSONLDSchema) => {
    const { breadcrumbs } = data
    return <BreadcrumbJsonLd {...breadcrumbsJSONLDMapper(breadcrumbs)} />
  },
  [JsonLDSchemaTypeSchema.enum.sitelinks]: (data: JSONLDSchema, url: string) => {
    const { searchPotentialActions } = data
    return <SiteLinksSearchBoxJsonLd {...searchBoxJSONLDMapper(searchPotentialActions, url)} />
  },
  [JsonLDSchemaTypeSchema.enum.manual]: (data: JSONLDSchema) => {
    const { manualSchema } = data
    return (
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: manualSchema }} />
      </Head>
    )
  },
}

export type SEOComponentProps = { data: PageSEOFieldsExtendedType }

export const SEOComponent = ({ data }: SEOComponentProps) => {
  const router = useRouter()

  const JsonLD = () => {
    const jsonLD = data.jsonLD
    if (!jsonLD || !jsonLD.schemaType) return null
    return JsonLDSchemaTypes[jsonLD.schemaType]?.(jsonLD, router.asPath) ?? null
  }

  return (
    <>
      <NextSeo {...perPageSeoMapper(data)} />
      {JsonLD()}
    </>
  )
}

type DefaultSEOComponentProps = { globalSEO: GeneralSEOSchemaType }
export const DefaultSEOComponent = ({ globalSEO }: DefaultSEOComponentProps) => {
  const seoData = defaultSeoMapper(globalSEO)
  return <DefaultSeo {...seoData} />
}
