import { groq } from 'next-sanity'
import type { z } from 'zod'

import { basicContent, BasicContentSchema } from './basicContent'
import { annotationFields, AnnotationFieldsSchema } from './primitives'

export const basicContentWithLinks = groq`
  ${basicContent}
  ${annotationFields}
`

export const BasicContentWithLinksSchema = BasicContentSchema.merge(AnnotationFieldsSchema)

export type BasicContentWithLinksType = z.infer<typeof BasicContentWithLinksSchema>
