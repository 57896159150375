import { useEffect } from 'react'

const updateFooter = (): boolean => {
  const teconsent = document.getElementById('teconsent')
  const trustArcLink = document.querySelectorAll('[id^="icon-"]')

  const shadowFooterLinks = document
    ?.querySelector('#shadowContainerFooter')
    ?.shadowRoot?.querySelector('#footer-contentinfo-links')
  const footerLinks = document.getElementById('footer-contentinfo-links')
  const footer = footerLinks || shadowFooterLinks

  const linkClasses = footerLinks?.querySelector('a')?.classList
  const shadowLinkClasses = shadowFooterLinks?.querySelector('a')?.classList
  const footerClasses = linkClasses || shadowLinkClasses

  if (!teconsent || !trustArcLink[0] || !footerClasses || !footer) return false

  teconsent.style.setProperty('opacity', '1')
  teconsent.style.setProperty('line-height', 'normal')
  trustArcLink[0].classList.add(...footerClasses)
  footer.appendChild(teconsent)

  return true
}

export const useTrustArcCustomizations = () => {
  useEffect(() => {
    const updated = updateFooter()
    if (!updated) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            if (updateFooter()) {
              observer.disconnect()
            }
          }
        }
      })

      observer.observe(document.body, { childList: true, subtree: true })

      return () => {
        observer.disconnect()
      }
    }
  }, [])
}
