import dynamic from 'next/dynamic'
import type { NextRouter } from 'next/router'
import { memo, type RefObject } from 'react'
import useSWRImmutable from 'swr/immutable'

import { useImagePreload } from '@/components/hooks/useImagePreload'
import { useTimeDelayedRoutePopup } from '@/components/hooks/useTimeDelayedRoutePopup'
import { allEmailGates } from '@/sanity/queries/emailGates/allEmailGates'
import { allCampaigns } from '@/sanity/queries/popups/allCampaigns'
import { getAllEmailGates } from '@/sanity/services/emailGates/getAllEmailGates'
import { getAllCampaigns, getPopupPerPage } from '@/sanity/services/popups/getAllCampaigns'
import { useIsEmailExpired } from '@/store/emailGateStore'

import { getEmailGateForPage } from './EmailGate/getEmailGateForPage'
import { ModalTriggerListener } from './modalTriggerListener/modalTriggerListener'
import { getShouldShowEmailGate, getShouldShowPopup, isCampaignCollectEmail } from './popupsUtils'
import { openPopupCb } from './utils'

const bypassEmailGateKey = 'authkey'

type PopUpsProps = {
  router: NextRouter
  pageType?: string
  usePortalForRecaptcha?: boolean
  shadowRootRef?: RefObject<HTMLElement>
}
const PopUps = ({ router, pageType, usePortalForRecaptcha, shadowRootRef }: PopUpsProps) => {
  const EmailGate = dynamic(() => import('./EmailGate'), { ssr: false })
  const url = router.asPath
  const bypassEmailGate = router.query?.[bypassEmailGateKey] as string | undefined
  const isEmailExpired = useIsEmailExpired()

  const { data: popupsPerPages } = useSWRImmutable(allCampaigns, getAllCampaigns)
  const { data: emailGates } = useSWRImmutable(allEmailGates, getAllEmailGates)
  const popupForPage = getPopupPerPage({ url, pageType, popupsPerPages })
  const emailGateForPage = getEmailGateForPage({ url, pageType, emailGates })

  const shouldShowEmailGate = getShouldShowEmailGate({
    isEmailGateAssigned: emailGateForPage !== null,
    isEmailExpired,
    isBypassEmailGate: bypassEmailGate !== undefined,
  })
  const emailGate = shouldShowEmailGate ? emailGateForPage : null

  const shouldShowPopup = getShouldShowPopup({
    shouldShowEmailGate,
    isEmailExpired,
    isPopupAssigned: popupForPage !== null,
    isPopupCollectEmail: isCampaignCollectEmail(popupForPage),
  })

  useTimeDelayedRoutePopup(() => {
    if (shouldShowPopup) return popupForPage ? openPopupCb(popupForPage) : null
    return null
  }, popupForPage?.triggers.triggerTime)

  useImagePreload(shouldShowPopup ? popupForPage?.image?.src : undefined)

  return (
    <>
      <ModalTriggerListener usePortalForRecaptcha={usePortalForRecaptcha} />
      <EmailGate emailGate={emailGate} shadowRootRef={shadowRootRef} />
    </>
  )
}

const MemoizedPopUps = memo(PopUps)

export { MemoizedPopUps as PopUps }
