import {
  GTMRegisteredInterestFormStartedText,
  GTMRegisteredInterestFormSubmittedText,
  GTMRegisteredInterestFormViewText,
} from '@/common/constants/gtmConstants'
import { gtmEvent } from '@/common/utils/gtm/gtmEvent'

export const gtmRegisteredInterestViewEvent = (event_data: DataLayerEventDataProps) => {
  gtmEvent(GTMRegisteredInterestFormViewText.event, {
    ...GTMRegisteredInterestFormViewText,
    event_data,
  })
}

export const gtmRegisteredInterestStartedEvent = (event_data: DataLayerEventDataProps) => {
  gtmEvent(GTMRegisteredInterestFormStartedText.event, {
    ...GTMRegisteredInterestFormStartedText,
    event_data,
  })
}

export const gtmRegisteredInterestSubmittedEvent = (
  event_data: DataLayerEventDataProps,
  user_data: DataLayerUserDataProps
) => {
  gtmEvent(GTMRegisteredInterestFormSubmittedText.event, {
    ...GTMRegisteredInterestFormSubmittedText,
    event_data,
    user_data,
  })
}
