import { groq } from 'next-sanity'
import { z } from 'zod'

import {
  basicContentWithLinks,
  BasicContentWithLinksSchema,
} from '../portableText/basicContentWithLinks'

export enum MediaBuilderTypes {
  IMAGE = 'Image',
  CUSTOM_VIDEO = 'Custom Video',
  VIDEO_RECORD = 'Video Record',
  UNSET = 'Unset',
}

export const mediaBuilder = groq`
  "type": coalesce(type, "${MediaBuilderTypes.UNSET}"),
  type == '${MediaBuilderTypes.IMAGE}' => {
    caption[] {
      ${basicContentWithLinks}
    },
    type,
    "alt": image.alt,
    "image": image.asset-> {
      url,
      _id,
      _type,
      metadata { dimensions { width, height }, lqip}
    },
    backgroundColor,
  },
  type == '${MediaBuilderTypes.CUSTOM_VIDEO}' => {
    type,
    "video": video.asset->{
      url,
    },
  },
  type == '${MediaBuilderTypes.VIDEO_RECORD}' => {
    type,
    "video": videoSelectorReference.videoReference->{
      desktopProviderURL,
      "posterImage": posterImage.asset->{url}.url,
      publishDate,
      title,
      category,
      description[] {
        ${basicContentWithLinks}
      },
    },
    "videoType": videoSelectorReference.type
  },
  type == '${MediaBuilderTypes.UNSET}' => {
    type,
  },
`

// TODO update zod schema and groq query based on removed MediaTypeImage
// but leave only necessary fields
// https://github.com/Zwirner/web/pull/1047/files#diff-39e2d8197fba625c7042c579a9c37535f797a82c0903ebbab0784fc253aa41c4
export const MediaBuilderImageSchema = z.object({
  type: z.literal(MediaBuilderTypes.IMAGE),
  alt: z.nullable(z.string()),
  image: z.any(),
  backgroundColor: z.nullable(z.string()),
  caption: z.nullable(z.array(BasicContentWithLinksSchema)),
})
export const MediaBuilderCustomVideoSchema = z.object({
  type: z.literal(MediaBuilderTypes.CUSTOM_VIDEO),
  video: z.nullable(
    z.object({
      url: z.string(),
    })
  ),
})
/*
 * mobileProviderURL has been removed.
 * See https://linear.app/zwirner/issue/WEB-2419/remove-mobile-video-url-field-from-video-records
 */
export const MediaBuilderVideoRecordSchema = z.object({
  type: z.literal(MediaBuilderTypes.VIDEO_RECORD),
  video: z.nullable(
    z.object({
      desktopProviderURL: z.string(),
      posterImage: z.nullable(z.string()),
      publishDate: z.nullable(z.string()),
      title: z.string(),
      category: z.nullable(z.string()),
      description: z.nullable(z.array(BasicContentWithLinksSchema)),
    })
  ),
  videoType: z.nullable(z.string()),
})

export const MediaBuilderSchema = z.discriminatedUnion('type', [
  MediaBuilderImageSchema,
  MediaBuilderCustomVideoSchema,
  MediaBuilderVideoRecordSchema,
  z.object({ type: z.literal(MediaBuilderTypes.UNSET) }),
])

export type MediaBuilderImageType = z.infer<typeof MediaBuilderImageSchema>
export type MediaBuilderSchemaType = z.infer<typeof MediaBuilderSchema>
