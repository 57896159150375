import type {
  EmailGateData,
  EmailGateDataDictionary,
} from '@/sanity/services/emailGates/getAllEmailGates'
import { PageSections, type PageSectionsKey } from '@/sanity/services/popups/constants'
import { normalizeArgs } from '@/sanity/services/popups/getAllCampaigns'

type GetEmailGateForPageArgs = {
  url: string
  pageType: string | undefined
  emailGates: EmailGateDataDictionary | null | undefined
}
export const getEmailGateForPage = ({
  url,
  pageType,
  emailGates,
}: GetEmailGateForPageArgs): EmailGateData | null => {
  if (!emailGates || !url) return null

  const { unifiedUrlWithoutAnchor, unifiedPageType } = normalizeArgs({ url, pageType })

  const pageSectionsKeys = Object.keys(PageSections) as PageSectionsKey[]
  const sectionKey = pageSectionsKeys.find((key) => PageSections[key] === unifiedPageType)

  // Pick record by url if exists (single page. Ex.: /artists/marlene-dumas), it could be a subpage
  const key = Object.keys(emailGates).find((key) => unifiedUrlWithoutAnchor.startsWith(key))
  const emailGateByUrl = key ? emailGates[key] : null

  if (emailGateByUrl) {
    // check that the popup is not already displayed
    return emailGateByUrl
  }

  if (!sectionKey) return null
  // pick record by section if exists (group of pages. Ex.: /artists/*)
  const emailGateBySectionKey = emailGates[sectionKey]

  if (emailGateBySectionKey) {
    // check that the popup is not already displayed
    return emailGateBySectionKey
  }

  return null
}
