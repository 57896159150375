import { useEffect } from 'react'

declare global {
  interface Window {
    truste: any
  }
}

declare var truste: any

// Check if the truste library is available
const isTrusteAvailable = (): boolean => {
  return typeof window.truste !== 'undefined'
}

// Wait for the truste library to become available
const waitForTruste = (): Promise<void> => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (isTrusteAvailable()) {
        clearInterval(interval)
        resolve()
      }
    }, 100)
  })
}

export const useTrustArcReloadPage = () => {
  useEffect(() => {
    // Wait for truste to become available, then setup the event listener
    waitForTruste().then(() => {
      if (typeof truste !== 'undefined' && truste.eu && truste.eu.prefclose) {
        const originalPrefClose = truste.eu.prefclose
        let prevPrefCookie = truste.eu.bindMap.prefCookie
        truste.eu.prefclose = function () {
          originalPrefClose.apply(this, arguments)
          let newPrefCookie = truste.eu.bindMap.prefCookie
          // for some reason, TrustArc's preference is sometimes separated by a pipe instead of a comma
          if (prevPrefCookie.includes('|')) {
            prevPrefCookie = prevPrefCookie.replaceAll('|', ',')
          }
          if (newPrefCookie.includes('|')) {
            newPrefCookie = newPrefCookie.replaceAll('|', ',')
          }
          // if the preference cookie has changed, reload the page
          if (prevPrefCookie !== newPrefCookie) {
            setTimeout(() => {
              window.location.reload()
            }, 50)
          }
          prevPrefCookie = truste.eu.bindMap.prefCookie
        }
        // Restore original function on unmount
        return () => {
          truste.eu.prefclose = originalPrefClose
        }
      }
    })
  }, [])
}
