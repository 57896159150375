import { getYear } from '@/common/utilsMappers/date.mapper'
import type { FooterDataSchemaType } from '@/sanity/queries/common/footerData'

const emptyHeaderItems = { items: [] }
const emptySocialMedia = {
  weChat: '',
  instagram: '',
  youTube: '',
  facebook: '',
}
const defaultFooterCopies = {
  copies: {
    rights: `David Zwirner - All rights reserved ${getYear()}`,
  },
}

export const getHeaderProps = (data: any) => {
  const { headerData = {} } = data ?? {}
  const { footerData = {} } = data ?? {}
  const { socialMedia } = footerData ?? {}

  const { Menu } = headerData ?? {}

  return {
    menu: Menu ?? emptyHeaderItems,
    socialMedia: socialMedia ?? emptySocialMedia,
  }
}
export const getFooterProps = (footerData: FooterDataSchemaType[number]) => {
  const { links, socialMedia } = footerData

  return {
    data: {
      links: links ?? [],
      socialMedia: socialMedia ?? emptySocialMedia,
      ...defaultFooterCopies,
    },
  }
}
