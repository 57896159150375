import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export const landingPageSessionKey = 'visitorState'

export type LandingPageState = {
  landingPageURL: {
    url: string | undefined
    expiry: number
  }
}

type LandingPageStateStore = LandingPageState & {
  setLandingPageURL: (landingPageURL: { url: string | undefined; expiry: number }) => void
}

const INITIAL_STATE: LandingPageState = {
  landingPageURL: {
    url: undefined,
    expiry: 0,
  },
}

export const useLandingPageStore = create<LandingPageStateStore>()(
  persist(
    (set) => ({
      ...INITIAL_STATE,
      setLandingPageURL: (landingPageURL) => set({ landingPageURL }),
    }),
    { name: landingPageSessionKey, storage: createJSONStorage(() => sessionStorage) }
  )
)
