// ============ URLS =============
export const ARTISTS_AVAILABLE_WORKS_URL = '/available-artworks'
export const ARTISTS_EXHIBITIONS_URL = '/exhibitions'
export const ARTISTS_NEWS_URL = '/news'
export const ARTISTS_PRESS_URL = '/press'
export const ARTISTS_SURVEY_URL = '/survey'
export const ARTISTS_URL = '/artists'
export const BOOKS_URL = 'https://www.davidzwirnerbooks.com/'
export const COLLECT_BOOKS_URL = '/collect/books'
export const COLLECT_URL = '/collect'
export const DZ30YEARS_URL = '/30years'
export const EXHIBITIONS_URL = '/exhibitions'
export const EXHIBITION_CHECKLIST_URL = '/checklist'
export const EXHIBITION_INSTALLATION_URL = '/installation-views'
export const FAIRS_URL = '/fairs'
export const NEWS_URL = '/news'
export const PAST_EXHIBITIONS_URL = '/exhibitions/past-exhibitions'
export const PREVIEW_EXIT_URL = '/api/exit-draft'
export const SURVEY_URL = '/survey'

// ============ COPIES ===========
export const ALL_EXHIBITIONS = 'All Exhibitions'
export const ARTISTS = 'Artists'
export const ARTIST_AND_CONTRIBUTORS = 'Artist and Contributors'
export const ARTIST_NEWS = 'Artist News'
export const AVAILABLE_WORKS = 'Available Artworks'
export const CHECKLIST = 'Checklist'
export const CLOSE = 'close'
export const COMING_SOON = 'Coming Soon'
export const CURRENT = 'Current'
export const DEFAULT_ONLINE_EXHIBITION_EYEBROW = 'Viewing Room'
export const DETAILS = 'Details'
export const DOWNLOAD_PRESS_RELEASE = 'Download Press Release'
export const DRAFTMODE_BANNER_ID = 'sharePreview-'
export const DRAFTMODE_SENTRY = 'DRAFTMODE '
export const DRAFT_VIEW = 'Draft View'
export const ECOMM_COLLECT_CARD_CTA = 'View Collection'
export const ECOMM_COLLECT_CARD_EYEBROW = 'Collection'
export const EXHIBITION = 'Exhibition'
export const EXHIBITIONS = 'Exhibitions'
export const EXIT = 'Exit'
export const EXPECT_THE_LATEST_INFORMATION =
  'Gallery news and updates will arrive soon in your inbox.'
export const EXPLORE = 'Explore'
export const EXPLORE_ALL_ARTWORKS = 'Explore all Artworks'
export const EXPLORE_ARTIST_NEWS = 'Explore Artist News'
export const EXPLORE_AVAILABLE_WORKS = 'Explore Available Artworks'
export const EXPLORE_BOOKS = 'Explore Books'
export const EXPLORE_MORE = 'Explore More'
export const FAIR = 'Fair'
export const FEATURED = 'Featured'
export const GET_IN_TOUCH = 'Get in Touch'
export const INQUIRE = 'Inquire'
export const INSTALLATION_VIEWS = 'Installation Views'
export const INTERESTED_IN = 'Interested in'
export const INTERESTED_IN_THIS_EXHIBITION = 'Interested in this exhibition?'
export const JOIN_OUR_MAILING_LIST =
  'Subscribe to receive emails on upcoming exhibitions, available works, artist events, and more.'
export const JOIN_OUR_MAILING_LIST_ERROR = 'Failed to send your request'
export const JOIN_OUR_MAILING_LIST_SUCCESS = 'Thank you for subscribing to our newsletter'
export const LEARN_MORE = 'Learn More'
export const LISTEN_NOW = 'Listen now'
export const LOCATION = 'Location'
export const MUSEUM_HIGHLIGHTS = 'Museum Highlights'
export const NEWSLETTER = 'Newsletter'
export const NOW_OPEN = 'Now Open'
export const ONLINE = 'Online'
export const ONLINE_EXHIBITION_TITLE = 'Online Exhibition'
export const OPEN_NOW = 'Open Now'
export const PAST = 'Past'
export const PLEASE_PROVIDE_YOUR_CONTACT_SHORT = 'please provide your contact information.'
export const PURCHASE = 'Purchase'
export const READ_MORE = 'Read More'
export const REGISTERED_INTEREST = 'Registered Interest'
export const REGISTERING_INTEREST_SUCCESS_SUBTITLE =
  'Expect updates on this artist and other gallery news to arrive to your inbox soon.'
export const REGISTERING_INTEREST_SUCCESS_TITLE = 'Thank you for registering your interest'
export const REQUEST_A_PREVIEW = 'Request a Preview'
export const REVIEW_MODE = 'Review Mode:'
export const SELECTED_PRESS = 'Selected Press'
export const SELECTED_TITLES = 'Selected Titles'
export const SIGN_UP = 'Sign Up'
export const SUBSCRIBE = 'Subscribe'
export const SURVEY = 'Survey'
export const THIS_ARTWORK = 'this artwork'
export const TO_LEARN_MORE_ABOUT = 'To learn more about'
export const TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS_EXTENDED =
  'To learn more about available works, please provide your contact information and a member of the gallery will be in touch shortly.'
export const TO_LEARN_MORE_ABOUT_THESE_WORKS =
  'To learn more about these works, please provide your contact information and a member of the gallery will be in touch shortly.'
export const TO_LEARN_MORE_ABOUT_THIS_ARTWORK =
  'To learn more about this artwork, please provide your contact information.'
export const UPCOMING = 'Upcoming'
export const VIEW_MORE = 'View More'
export const WANT_TO_KNOW_MORE = 'Be the first to know'
