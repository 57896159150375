import { effect, signal } from '@preact/signals-react'

import type { ShopifyComponent } from './common'
import { getVariantProps } from './common'

declare var ShopifyBuy: any

// Signals for Global client & per page components
export const ShopifyClient = signal(null)
export const ShopifyComponents = signal<ShopifyComponent[]>([])

// Load only one UI element per id
export const ShopifyLoadedItems = signal<Record<string, boolean>>({})

// Check Shopify docs https://shopify.github.io/buy-button-js for extra information
effect(() => {
  if (ShopifyClient.value) {
    const ui = ShopifyBuy.UI.init(ShopifyClient.value)
    if (!ShopifyComponents.value.length) return
    ShopifyComponents.value.forEach((component) => {
      const { productId, nodeId, componentType, variant, text } = component ?? {}
      const componentOptions = text
        ? {
            text: {
              button: text,
            },
          }
        : undefined
      if (!nodeId || !productId) return
      const options = getVariantProps({
        styleVariant: variant,
        options: componentOptions,
      })
      const primaryButton = document.getElementById(nodeId)
      if (!primaryButton || primaryButton?.hasChildNodes() || ShopifyLoadedItems.value[nodeId]) {
        return
      }
      ShopifyLoadedItems.value[nodeId] = true
      ui.createComponent(componentType, {
        id: productId,
        node: primaryButton,
        options,
      })
    })
  }
})
