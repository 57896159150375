export function isCampaignCollectEmail(campaign: { type: string } | null): boolean {
  return campaign === null ? false : campaign.type === 'newsletter'
}

export function getShouldShowEmailGate({
  isEmailGateAssigned,
  isEmailExpired,
  isBypassEmailGate,
}: {
  isEmailGateAssigned: boolean
  isEmailExpired: boolean
  isBypassEmailGate: boolean
}): boolean {
  if (!isEmailGateAssigned) return false

  if (isBypassEmailGate) return false

  return isEmailExpired
}

export function getShouldShowPopup({
  shouldShowEmailGate,
  isEmailExpired,
  isPopupAssigned,
  isPopupCollectEmail,
}: {
  shouldShowEmailGate: boolean
  isEmailExpired: boolean
  isPopupAssigned: boolean
  isPopupCollectEmail: boolean
}): boolean {
  if (!isPopupAssigned) return false

  if (shouldShowEmailGate) return false

  if (!isPopupCollectEmail) return true

  return isEmailExpired
}
