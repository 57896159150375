import { parseCookieValue } from './parseCookieValue'

export const getCookie = (name: string) => {
  const trimmedName = name.trim()
  const cookieArray = document.cookie.split(';')

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i]?.trim() ?? ''

    if (cookie.indexOf(trimmedName + '=') === 0) {
      const value = cookie.substring(trimmedName.length + 1, cookie.length)
      return parseCookieValue(value)
    }
  }

  return null
}
