export enum SizeUnit {
  Bytes = 'bytes',
  KB = 'kb',
  MB = 'mb',
  GB = 'gb',
}

export function getStringSize(str: string, unit: SizeUnit = SizeUnit.KB): number {
  const buffer = Buffer.from(str, 'utf8')
  const sizeInBytes = buffer.length

  switch (unit) {
    case SizeUnit.Bytes:
      return sizeInBytes
    case SizeUnit.KB:
      return sizeInBytes / 1024
    case SizeUnit.MB:
      return sizeInBytes / (1024 * 1024)
    case SizeUnit.GB:
      return sizeInBytes / (1024 * 1024 * 1024)
    default:
      throw new Error('Invalid unit specified')
  }
}
