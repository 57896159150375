import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { COMING_SOON, NOW_OPEN, OPEN_NOW } from '@/common/constants/commonCopies'
import { newYorkTimeZone } from '@/common/constants/timezone'
import { isExhibitionOpen } from '@/components/containers/exhibitions/exhibitionsLandingContainer/utils'
import type { FieldsPerTypeSchemaType } from '@/sanity/queries/components/componentTypesData'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)

type DateRange = {
  from: string
  to: string
}
type DateSelectionMapperProps = {
  year?: string
  dateRange?: DateRange
  approximate?: string
}

const RecordType = {
  EXHIBITION: 'exhibitionPage',
  FAIR: 'fairPage',
  ONLINE_EXHIBITION: 'exceptionalWork',
  EXCEPTIONAL_WORK: 'onlineExhibitionPage',
} as const

export const getNewYorkTimestamp = (): number => dayjs.tz(dayjs(), newYorkTimeZone).valueOf()

/**
 * Get the year from a date string
 * @param date - date string, if not provided, it will return the current year
 * @returns year
 */
export const getYear = (date?: string | number | Date): number => {
  const parseDate = dayjs(date)
  if (!parseDate.isValid()) return dayjs.tz(dayjs(), newYorkTimeZone).year()
  return dayjs.tz(date, newYorkTimeZone).year()
}

export const dateSelectionArtworkMapperBook = (data: DateSelectionMapperProps | string) => {
  if (typeof data === 'string') {
    return {
      year: getYear().toString(),
    }
  }

  const { year, dateRange, approximate } = data ?? {}
  if (year) return { year }
  if (approximate) return { year: getYear(approximate) }

  const { from, to } = dateRange ?? {}
  if (!from && !to) return { year: '' }

  const fromYear = from ? getYear(from).toString() : ''
  const toYear = to ? getYear(to).toString() : ''
  const rangeYear = fromYear === toYear ? fromYear : `${fromYear} - ${toYear}`
  const selectedYear = fromYear && toYear ? rangeYear : ''

  return {
    year: selectedYear,
  }
}

export const mapSingleDateFormat = (dateISO?: string | null): string => {
  if (!dateISO) return ''
  const dateInNY = dayjs.tz(dateISO, newYorkTimeZone)
  return dateInNY.format('MMMM D, YYYY')
}

export const fromToDatesText = (startDateISO: string, endDateISO: string): string => {
  const startDateNY = dayjs.tz(startDateISO, newYorkTimeZone)
  const endDateNY = dayjs.tz(endDateISO, newYorkTimeZone)

  const isSameYear = startDateNY.isSame(endDateNY, 'year')

  if (!isSameYear) {
    return `${mapSingleDateFormat(startDateISO)}—${mapSingleDateFormat(endDateISO)}`
  }

  const isSameMonth = startDateNY.isSame(endDateNY, 'month')
  const endDateFormatted = isSameMonth ? endDateNY.format('D') : endDateNY.format('MMMM D')

  const startMonthAndDay = startDateNY.format('MMMM D')
  const startYear = startDateNY.format('YYYY')

  return `${startMonthAndDay}—${endDateFormatted}, ${startYear}`
}

export const mapStatus = (
  data: FieldsPerTypeSchemaType
): {
  isOpen: boolean
  isClosed: boolean
  isComingSoon: boolean
  status: string
} => {
  if (
    data._type !== RecordType.FAIR &&
    data._type !== RecordType.EXHIBITION &&
    data._type !== RecordType.ONLINE_EXHIBITION &&
    data._type !== RecordType.EXCEPTIONAL_WORK
  )
    throw new Error('Invalid data type')

  const { startDate, endDate, displayDate, _type } = data
  if (displayDate) {
    return {
      isOpen: false,
      isClosed: false,
      isComingSoon: false,
      status: displayDate,
    }
  }

  const startDateObj = dayjs.tz(startDate, newYorkTimeZone)
  const endDateObj = dayjs.tz(endDate, newYorkTimeZone)

  if (!startDateObj.isValid() || !endDateObj.isValid()) {
    return {
      isOpen: false,
      isClosed: false,
      isComingSoon: false,
      status: '',
    }
  }

  const startDateInNY = startDateObj.startOf('day')
  const endDateInNY = endDateObj.endOf('day')
  const currentTimeInNY = dayjs.tz(dayjs(), newYorkTimeZone)

  const datesText = fromToDatesText(startDate, endDate)

  let isOpen = false
  if (data._type === RecordType.EXHIBITION)
    isOpen = isExhibitionOpen({ startDate, endDate, locations: data.locations })
  if (data._type === RecordType.FAIR)
    isOpen = isFairOpen({ startDate, endDate, location: data.location })

  const isComingSoon = currentTimeInNY.isBefore(startDateInNY)
  const isClosed = currentTimeInNY.isAfter(endDateInNY)
  const dateLabel = getEventDateLabel({ isOpen, isComingSoon, _type })

  return {
    isOpen,
    isClosed,
    isComingSoon,
    status: `${dateLabel ? `${dateLabel}: ` : ''}${datesText}`,
  }
}

type GetEventDateLabelType = {
  isOpen: boolean
  isComingSoon: boolean
  _type: (typeof RecordType)[keyof typeof RecordType]
}
const getEventDateLabel = ({ isOpen, isComingSoon, _type }: GetEventDateLabelType) => {
  if (_type === RecordType.EXHIBITION) {
    if (isOpen) {
      return NOW_OPEN
    }

    if (isComingSoon) {
      return COMING_SOON
    }
  }

  if (_type === RecordType.FAIR) {
    if (isOpen) {
      return OPEN_NOW
    }
  }
}

export const articleDatesMapper = (date: string | null) => {
  if (!date) return null
  const customTime = '12:00:00.000'
  const currentTimeInNY = dayjs.tz(`${date} ${customTime}`, newYorkTimeZone)
  return currentTimeInNY.format('MMMM D, YYYY')
}

type isFairOpen = { startDate: string; endDate: string; location: any }
export const isFairOpen = (data: isFairOpen) => {
  const { startDate, endDate, location } = data

  const timeZoneLoc = location?.timezone ?? newYorkTimeZone
  const startDateInTZ = dayjs.tz(startDate, timeZoneLoc).startOf('day')
  const endDateInTZ = dayjs.tz(endDate, timeZoneLoc).endOf('day')
  const currentTimeInTZ = dayjs.tz(dayjs(), timeZoneLoc)

  if (startDateInTZ.isValid() && endDateInTZ.isValid()) {
    return currentTimeInTZ.isBetween(startDateInTZ, endDateInTZ, null, '[]')
  }

  return false
}
