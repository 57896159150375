import { INQUIRY_CATEGORIES, INQUIRY_TYPES } from '@zwirner/design-system'
import { useRouter } from 'next/router'
import { useRef, useState } from 'react'

import { INQUIRE } from '@/common/constants/commonCopies'
import { ErrorType, GTMErrorMessageEvent } from '@/common/utils/gtm/GTMErrorMessageEvent'
import {
  gtmInquiryFormStartedEvent,
  gtmInquiryFormSubmitEvent,
} from '@/common/utils/gtm/gtmInquiryFormEvent'
import {
  gtmRequestPreviewFormStartedEvent,
  gtmRequestPreviewFormSubmitEvent,
} from '@/common/utils/gtm/gtmRequestPreviewFormEvent'
import { useInquiryType } from '@/common/utils/inquire/inquiryType'
import { captchaInitObserver, removeCaptchaObserver } from '@/common/utils/recaptcha/observer'
import { getNewYorkTimestamp } from '@/common/utilsMappers/date.mapper'
import type { InquireModalProps } from '@/components/hooks/useOpenInquiryDispatch'
import { builder } from '@/sanity/imageBuilder'
import { ModalTypes } from '@/sanity/types'
import { createRandomUUID } from '@/sanity/uuid'
import { sendInquiry } from '@/services/inquireService'
import { useLandingPageStore } from '@/store/landingPageStore'
import { portableTextToText } from '@/utils/sanity/portableTextToText'

export const INQUIRE_HASH_KEY = 'inquire'
export const ARTWORK_ID_KEY = 'artworkId'

const FORM_ID_INQUIRY = 'inquiry'

export const artworkToPayloadAdapter = (artwork: any, status?: string) => {
  if (!artwork) {
    return
  }
  const {
    _id,
    artworkType,
    artists,
    dimensions,
    inventoryId,
    title,
    photos,
    dateSelection,
    price,
    editionInformation,
    productInformation,
    additionalCaption,
    salesInformation,
    medium,
  } = artwork
  const firstAsset = photos?.[0]?.image
  const image = firstAsset ? builder.image(firstAsset).url() : ''
  const payload: Record<string, any> = {
    artistFullName: artists?.[0]?.fullName || '',
    artworkType: artworkType || '',
    dimensions: portableTextToText(dimensions),
    id: _id,
    image: image || '',
    inventoryId: inventoryId || '',
    status: status || '',
    title,
    year: dateSelection?.year || '',
    editionInformation: portableTextToText(editionInformation),
    medium: medium || '',
    productInformation: portableTextToText(productInformation),
    additionalCaption: portableTextToText(additionalCaption),
    salesInformation: portableTextToText(salesInformation),
  }

  if (price) {
    payload.price = price
  }
  return payload
}

export const useHashRoutedInquiryModal = (type = ModalTypes.INQUIRE) => {
  const { url: landingPageURL = '' } = useLandingPageStore((state) => state.landingPageURL)
  const [isOpen, setIsOpen] = useState(false)
  const { replace } = useRouter()
  const inquiryType = useInquiryType()
  const recaptchaRef = useRef<HTMLFormElement>()
  const [inquireModalProps, setInquireModalProps] = useState<InquireModalProps>()
  const { contextData, subtitle, title = INQUIRE } = inquireModalProps ?? {}
  const { artwork, ctaText, status } = contextData ?? {}
  const onClose = () => {
    const { pathname } = window.location

    setIsOpen(false)
    replace({ pathname, hash: '' }, undefined, { scroll: false })
  }
  const openInquireModal = ({
    inquireModalProps,
    options,
  }: {
    inquireModalProps: InquireModalProps
    options: any
  }) => {
    const { contextData, inquiryCategory } = inquireModalProps
    const { pathname, search, hash } = window.location

    if (options?.useAnchor && !hash.includes(`#${INQUIRE_HASH_KEY}`)) {
      replace({
        pathname,
        query: search,
        hash:
          inquiryCategory === INQUIRY_CATEGORIES.ARTWORK && contextData?.id
            ? `${INQUIRE_HASH_KEY}?${ARTWORK_ID_KEY}=${contextData.id}`
            : 'inquire',
      })
    }

    setInquireModalProps(inquireModalProps)
    setIsOpen(true)
  }

  const onDirty = () => {
    type === ModalTypes.REQUEST_A_PREVIEW &&
      inquireModalProps &&
      gtmRequestPreviewFormStartedEvent(inquireModalProps)

    type === ModalTypes.INQUIRE &&
      inquireModalProps &&
      gtmInquiryFormStartedEvent(inquireModalProps)
  }

  const onSubmit = async (formValues: Record<string, any>) => {
    const { inquiryCategory } = inquireModalProps ?? {}

    // TODO check result of recaptcha before submitting form
    const observer = captchaInitObserver()

    await recaptchaRef?.current?.executeAsync()

    removeCaptchaObserver(observer)

    type === ModalTypes.REQUEST_A_PREVIEW &&
      inquireModalProps &&
      gtmRequestPreviewFormSubmitEvent(inquireModalProps, formValues?.email)

    type === ModalTypes.INQUIRE &&
      inquireModalProps &&
      gtmInquiryFormSubmitEvent(inquireModalProps, formValues?.email)

    const defaultInquiryCategory =
      type === ModalTypes.REQUEST_A_PREVIEW
        ? INQUIRY_CATEGORIES.REQUEST_PREVIEW
        : INQUIRY_CATEGORIES.GENERAL

    const inquiryPayload: Record<string, any> = {
      ...formValues,
      id: createRandomUUID(),
      currentUrl: window.location.href,
      timestamp: getNewYorkTimestamp(),
      formId: FORM_ID_INQUIRY,
      artistFullName: inquiryType === INQUIRY_TYPES.ARTIST ? contextData?.title : undefined,
      artwork: artworkToPayloadAdapter(artwork, ctaText),
      status,
      ctaText,
      inquiryType,
      inquiryCategory: inquiryCategory || defaultInquiryCategory,
      phone: formValues?.phone || '',
      pageTitle: document.title || '',
      landingPageURL,
    }

    return sendInquiry(inquiryPayload)
      .then((result) => {
        return { isSuccess: !result?.error, error: result?.error }
      })
      .catch((error) => {
        GTMErrorMessageEvent({
          error_message: error.error.message,
          type: ErrorType.FORM,
        })
        return { isSuccess: false, error }
      })
  }

  return {
    isOpen,
    onClose,
    onDirty,
    onSubmit,
    openInquireModal,
    recaptchaRef,
    subtitle,
    title,
  }
}
