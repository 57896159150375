import { groq } from 'next-sanity'
import { z } from 'zod'

import { SanityImageSchema } from '../validationPrimitives'

export const JsonLDSchemaTypeSchema = z.enum([
  'article',
  'breadcrumb',
  'sitelinks',
  'manual',
  'blog',
])

const jsonLDFields = groq`
    schemaType,
    (schemaType == '${JsonLDSchemaTypeSchema.enum.article}' || schemaType == '${JsonLDSchemaTypeSchema.enum.blog}') => {
      article-> {
        _updatedAt,
        _createdAt,
        author->,
        title,
        images,
        publisherName,
        description,
        publisherLogo
      }
    },
    schemaType == '${JsonLDSchemaTypeSchema.enum.breadcrumb}' => {
      breadcrumbs
    },
    schemaType == '${JsonLDSchemaTypeSchema.enum.sitelinks}' => {
      searchPotentialActions
    },
    schemaType == '${JsonLDSchemaTypeSchema.enum.manual}' => {
      manualSchema
    },
`

export const pageSEOFields = groq`
  pageTitle,
  metaDescription,
  h1Header,
  _type,
  robotsNoIndex,
  robotsNoFollow,
  imageMeta {
    _type,
    asset,
    "alt": asset->altText
  },
  socialTitle,
  socialDescription,
  jsonLD {
    ${jsonLDFields}
  }
`

export const PageSEOFieldsSchema = z.object({
  pageTitle: z.nullable(z.string()),
  metaDescription: z.string().nullish(),
  h1Header: z.any(),
  robotsNoIndex: z.boolean().nullish(),
  robotsNoFollow: z.boolean().nullish(),
  imageMeta: z
    .nullable(SanityImageSchema.merge(z.object({ alt: z.nullable(z.string()) })))
    .nullish(),
  socialTitle: z.string().nullish(),
  socialDescription: z.any(),
  canonical: z.string().nullable(),
  jsonLD: z
    .object({
      schemaType: JsonLDSchemaTypeSchema.nullish(),
      article: z.any().nullish(),
      breadcrumbs: z.array(z.any()).nullish(),
      searchPotentialActions: z.array(z.any()).nullish(),
      manualSchema: z.any().nullish(),
    })
    .nullish(),
})

export const PageSEOFieldsExtendedSchema = PageSEOFieldsSchema.merge(
  z.object({
    title: z.string().nullish(),
    titleTemplate: z.string().nullish(),
  })
)

export type PageSEOFieldsExtendedType = z.infer<typeof PageSEOFieldsExtendedSchema>

export type PageSEOFieldsType = z.infer<typeof PageSEOFieldsSchema>

export const addFullSlugCanonical = (seoData: PageSEOFieldsExtendedType, slug?: string) => {
  const { canonical } = seoData
  if (!canonical || !slug) return seoData
  return {
    ...seoData,
    canonical: `${canonical}${slug}`,
  }
}
