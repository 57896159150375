import type { NewsletterSubscription, RegisteredInterest } from '@/common/api/mail/types'
import { postJSONRequest } from '@/services/postJSONRequest'

export type SubscriptionData = {
  access?: boolean
  email: string
  events?: boolean
  news?: boolean
  tags?: string[] | null
}

const sanitizeTags = (tags: string[] | null | undefined) => {
  if (Array.isArray(tags) && tags.length > 0) return tags
  return undefined
}
const endpointUrl = '/api/user/subscribe'

export const sendSubscribeRequest = async (data: SubscriptionData, sourceUrl?: string) => {
  const { email, access, events, news, tags } = data

  const payload: NewsletterSubscription = {
    type: 'newsletter',
    email,
    interests: { access, events, news },
    sourceUrl,
    tags: sanitizeTags(tags),
  }

  return postJSONRequest({
    context: 'Subscribe request',
    data: payload,
    endpointUrl,
  }).then((result) => ({ isSuccess: !result?.error, error: result?.error }))
}

export type RegisteredInterestData = {
  access?: boolean
  ctaText?: string | null
  currentUrl?: string
  email: string
  events?: boolean
  formId: 'inquiry'
  id?: string
  inquiryCategory?: string
  inquiryType?: string
  landingPageURL?: string
  news?: boolean
  pageTitle?: string
  tags?: string[] | null
  timestamp?: number
}

export const sendRegisteredInterestRequest = async (data: RegisteredInterestData) => {
  const {
    access,
    ctaText,
    currentUrl,
    email,
    events,
    formId,
    id,
    inquiryCategory,
    inquiryType,
    landingPageURL,
    news,
    pageTitle,
    tags,
    timestamp,
  } = data

  const payload: RegisteredInterest = {
    type: 'registeredInterest',
    ctaText,
    currentUrl,
    email,
    formId,
    id,
    inquiryCategory,
    inquiryType,
    interests: { access, events, news },
    landingPageURL,
    pageTitle,
    tags: sanitizeTags(tags),
    timestamp,
  }

  return postJSONRequest({
    context: 'Send inquiry',
    data: payload,
    endpointUrl,
  }).then((result) => ({ isSuccess: !result?.error, error: result?.error }))
}
