import type { DzPortableTextProps } from '@zwirner/design-system'

import { DzPortableText } from '@/components/wrappers/DzPortableTextWrapper'

type PortableTextElementProps = {
  text: DzPortableTextProps['portableProps']['value'] | null
  customStyles?: Record<string, string>
  customProps?: Record<string, any>
  containerStyles?: string
  charLimit?: number
  bottomIndent?: boolean
}

export const createPortableTextElement = ({
  text,
  customStyles = { normal: '!mx-0' },
  customProps,
  containerStyles = '',
  charLimit,
  bottomIndent = false,
}: PortableTextElementProps) => {
  if (!text) return null

  return (
    <DzPortableText
      portableProps={{ value: text } as DzPortableTextProps['portableProps']}
      customStyles={customStyles}
      containerStyles={containerStyles}
      charLimit={charLimit}
      customProps={customProps}
      bottomIndent={bottomIndent}
    />
  )
}

type SafeTextProps<T, K> = {
  key?: K
  prefix?: T
} & PortableTextElementProps

export const safeText = <K extends string, T extends string = 'portableText'>({
  key,
  text,
  prefix = 'portableText' as T,
  customStyles = { normal: '!mx-0' },
  containerStyles = '',
  charLimit,
  bottomIndent = false,
}: SafeTextProps<T, K>):
  | { [key in K]: string }
  | { [key in `${T}${Capitalize<K>}`]: JSX.Element | null }
  | { [key in never]: never } => {
  if (!text) return {}

  const element = createPortableTextElement({
    text,
    customStyles,
    containerStyles,
    charLimit,
    bottomIndent,
  })

  if (!key) throw new Error('Key is required')

  if (typeof text === 'string') return { [key]: text }
  const capitalizedKey = key?.charAt(0)?.toUpperCase() + key?.slice(1)

  return { [`${prefix}${capitalizedKey}`]: element }
}
