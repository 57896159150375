import * as Sentry from '@sentry/nextjs'
import type { ReactNode } from 'react'

import { createPortableTextElement } from '@/common/utilsMappers/safe'
import { getClient } from '@/sanity/client'
import type {
  AllCampaignsType,
  CustomNewsletterPopupFieldsType,
  PopupItemType,
} from '@/sanity/queries/popups/allCampaigns'
import { AllCampaignsSchema } from '@/sanity/queries/popups/allCampaigns'
import { getCookie } from '@/utils/cookies/getCookie'

import type { PageSectionsKey, PageSectionsValues } from './constants'
import { PageSections } from './constants'

export type PopUpInfo = {
  campaignId: AllCampaignsType[0]['_id']
  campaignName: AllCampaignsType[0]['title']
  daysToExpire: AllCampaignsType[0]['cookieDaysToExpire']
  description: CustomNewsletterPopupFieldsType['description']
  displayAlways: PopupItemType['displayAlways']
  id: PopupItemType['_key']
  popupName: PopupItemType['name']
  tags: PopupItemType['tags']
  termsAndConditions: ReactNode
  title: CustomNewsletterPopupFieldsType['title']
  triggers: PopupItemType['triggers']
  type: PopupItemType['type']
  image: {
    src: string
    alt: string
  } | null
  primaryCTA: {
    text: string | null
    action?: string | null
    link?: {
      href: string | null
      blank?: boolean
    }
  } | null
}

type FilterSectionsDictionary = Record<string, PopUpInfo>

export const LandingTypeToURL = {
  availableArtworks: '/available-artworks',
  artistListing: '/artists',
  exhibitionsLanding: '/exhibitions',
  exhibitionsPast: '/exhibitions/past-exhibitions',
  home: '/',
} as const

type GetPopupPerPageArgs = {
  url: string
  pageType: string | undefined
  popupsPerPages: FilterSectionsDictionary | null | undefined
}

export const normalizeArgs = ({ url, pageType }: Omit<GetPopupPerPageArgs, 'popupsPerPages'>) => {
  // url refers to a subpage of an artist page if it consists of more than 3 parts. Ex.: /artists/marlene-dumas/survey
  const isArtistsSubpagePage = url.startsWith('/artists/') ? url.split('/').length > 3 : false
  const unifiedUrl = isArtistsSubpagePage ? url.split('/').slice(0, -1).join('/') : url
  const unifiedUrlWithoutAnchor = unifiedUrl.split('#')[0] ?? ''
  const unifiedPageType = isArtistsSubpagePage ? PageSections['All Artist Pages'] : pageType
  return { unifiedUrlWithoutAnchor, unifiedPageType }
}

export const getPopupPerPage = ({
  url,
  pageType,
  popupsPerPages,
}: GetPopupPerPageArgs): PopUpInfo | null => {
  if (!popupsPerPages || !url) return null

  const { unifiedUrlWithoutAnchor, unifiedPageType } = normalizeArgs({ url, pageType })

  const pageSectionsKeys = Object.keys(PageSections) as PageSectionsKey[]
  const sectionKey = pageSectionsKeys.find((key) => PageSections[key] === unifiedPageType) ?? ''
  if (!popupsPerPages[unifiedUrlWithoutAnchor] && !popupsPerPages[sectionKey]) return null

  // pick record by url if exists (single page. Ex.: /artists/marlene-dumas)
  const popupDataByUrl = popupsPerPages[unifiedUrlWithoutAnchor]
  if (popupDataByUrl) {
    // check that the popup is not already displayed
    const hasCookieByUrl = getCookie(popupDataByUrl.campaignName)
    if (hasCookieByUrl) return null
    return popupDataByUrl
  }

  // pick record by section if exists (group of pages. Ex.: /artists/*)
  const popupDataBySectionKey = popupsPerPages[sectionKey]

  if (popupDataBySectionKey) {
    // check that the popup is not already displayed
    const hasCookieBySection = getCookie(popupDataBySectionKey.campaignName)
    if (hasCookieBySection) return null
    return popupDataBySectionKey
  }

  return null
}

type AddToDictionaryType = {
  url: string | undefined
  popup: PopUpInfo
  dictionary: FilterSectionsDictionary
}

const addPopupToDictionary = (
  { url, popup, dictionary }: AddToDictionaryType,
  { allPagesKey }: { allPagesKey?: string }
) => {
  // only add first occurrence

  if (url && !dictionary?.[url]) {
    dictionary[url] =
      // If an ALL section already exists, takes precedence
      allPagesKey && dictionary[allPagesKey] ? dictionary[allPagesKey] : (null ?? popup)
  }
}

/* This mapper filters campaign popups an return an object with the following shape: { [url]: {highest priority popup for the url} } */
const getPopupDictionary = (campaigns: AllCampaignsType): FilterSectionsDictionary => {
  return campaigns.reduce<FilterSectionsDictionary>((prev, curr) => {
    const prevCopy = { ...prev }
    const { _id, title, popupsList = [], cookieDaysToExpire } = curr ?? {}
    popupsList?.forEach((item) => {
      const {
        displayAlways = false,
        type: popUpType,
        name,
        triggers,
        termsAndConditions,
        filterSections = [],
        tags = [],
      } = item

      filterSections?.forEach((section) => {
        const { type, page } = section ?? {}
        const { url, _type } = page ?? {}

        const sectionKey = Object.entries(PageSections).find(
          ([_, value]) => value === type
        )?.[0] as PageSectionsKey | undefined

        const isNewsOrPromo = item.type === 'newsletter' || item.type === 'customPromo'
        const popupInf: PopUpInfo = {
          campaignId: _id,
          campaignName: title,
          popupName: name,
          type: popUpType,
          daysToExpire: cookieDaysToExpire,
          displayAlways,
          termsAndConditions: createPortableTextElement({
            charLimit: 250,
            text: termsAndConditions?.content ?? [],
            customStyles: {
              normal: 'text-black-60 !text-xs',
            },
            customProps: {
              internalPathLink: {
                shortUnderlineOffset: true,
              },
              link: {
                shortUnderlineOffset: true,
              },
            },
          }),
          triggers,
          tags,
          image: null,
          primaryCTA: null,
          id: item._key,
          title: '',
          description: '',
        }

        if (isNewsOrPromo) {
          popupInf.title = item.title
          popupInf.description = item.description
          if (item.primaryCTA?.text) {
            popupInf.primaryCTA = item.primaryCTA
          }
          if (item.media?.type === 'Image') {
            popupInf.image = {
              src: item.media.image?.url,
              alt: item.media.alt ?? '',
            }
          }
        }

        let dictionaryKey
        let extras = {}

        // The target is a single page or a special page
        if (
          (type === PageSections['Single Page Record'] || type === PageSections['Special Pages']) &&
          url
        ) {
          dictionaryKey = url
          const indexOfAllPages = Object.values(PageSections).indexOf(_type as PageSectionsValues)
          const allPagesKey = Object.keys(PageSections)[indexOfAllPages]
          extras = { allPagesKey }
          // The target is a hardcoded page
        } else if (type && LandingTypeToURL[type as keyof typeof LandingTypeToURL]) {
          dictionaryKey = LandingTypeToURL[type as keyof typeof LandingTypeToURL]
          // The target is a custom url
        } else if (type === 'customSlug' && url) {
          dictionaryKey = url
          // The target is a section
        } else if (sectionKey) {
          dictionaryKey = sectionKey
        }
        addPopupToDictionary(
          {
            url: dictionaryKey,
            popup: popupInf,
            dictionary: prevCopy,
          },
          extras
        )
      })
    })
    return prevCopy
  }, {})
}

// called client-side with SWR
export async function getAllCampaigns(query: string): Promise<FilterSectionsDictionary> {
  // TODO add slug to client on popups call
  const { client } = getClient({
    draftMode: false,
    draftViewToken: '',
    slug: '',
    originName: 'service-getAllCampaigns',
    filePath: __filename,
  })
  const data = await client.fetch(query)
  const validatedData = AllCampaignsSchema.safeParse(data)
  if (!validatedData.success) {
    Sentry.captureException(validatedData.error)
    return {}
  }
  return getPopupDictionary(validatedData.data)
}
