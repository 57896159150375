import type { InquiryType } from '@zwirner/design-system'
import { INQUIRY_TYPES } from '@zwirner/design-system'
import { useRouter } from 'next/router'

const PATHNAMES_TO_INQUIRY_TYPES: Record<string, InquiryType> = {
  news: INQUIRY_TYPES.ARTICLE,
  artists: INQUIRY_TYPES.ARTIST,
  artworks: INQUIRY_TYPES.ARTWORKS,
  'available-artworks': INQUIRY_TYPES.AVAILABLE_WORKS,
  collect: INQUIRY_TYPES.COLLECT,
  exhibitions: INQUIRY_TYPES.EXHIBITION,
  home: INQUIRY_TYPES.HOME,
  stories: INQUIRY_TYPES.STORIES,
  'utopia-editions': INQUIRY_TYPES.UTOPIA_EDITIONS,
  other: INQUIRY_TYPES.OTHER,
}

export const pathToInquiryType = (path: string): InquiryType => {
  return path === '/'
    ? PATHNAMES_TO_INQUIRY_TYPES['home']
    : PATHNAMES_TO_INQUIRY_TYPES[path.split('/')?.[1]] || INQUIRY_TYPES.OTHER
}

export const useInquiryType = (): InquiryType => {
  // special paths like /collect have a pathname of '/[..slug]' so we must use asPath
  // but a few asPath instances have #inquire like /exhibitions/xyz#inquire so we use pathname
  const { asPath, pathname } = useRouter()
  const path = pathname.includes('...slug') ? asPath : pathname
  return pathToInquiryType(path)
}
