import * as Sentry from '@sentry/nextjs'

import { fetchWithTimeout } from '@/utils/fetch/fetchWithTimeout'

export const postJSONRequest = async ({
  endpointUrl,
  data,
  context = 'Please identify context',
}: {
  endpointUrl: string
  data: Record<string, unknown>
  context?: string
}): Promise<{ isSuccess?: boolean; [key: string]: any }> => {
  try {
    const response = await fetchWithTimeout(endpointUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
    if (!response.ok) {
      Sentry.captureMessage(`Error sending request: ${context}`, {
        level: 'error',
        extra: { data, context, response: `Status: ${response.status}` },
      })
      return { error: 'Error sending request' }
    }
    return await response.json()
  } catch (error) {
    Sentry.captureException(error, {
      level: 'error',
      extra: { data, context },
    })
    console.error('Error sending request: ', error)
    return { error }
  }
}
