import { groq } from 'next-sanity'
import { z } from 'zod'

import { LinkSchema } from '@/sanity/queries/components/builders/ctaBuilder'
import { SanitySlugSchema } from '@/sanity/queries/components/validationPrimitives'

const AnnotationLinkTypesSchema = z.enum(['link', 'internalPathLink', 'internalLink'])

export const StylesSchema = z.enum(['small', 'normal', 'large', 'xlarge'])

export const basicFields = groq`
  _key,
  _type,
`

const PathLinksSchema = z
  .object({
    _key: z.string(),
    _type: AnnotationLinkTypesSchema.extract([
      AnnotationLinkTypesSchema.enum.link,
      AnnotationLinkTypesSchema.enum.internalPathLink,
    ]),
  })
  .merge(LinkSchema)

const ReferenceLinkSchema = z.object({
  _key: z.string(),
  _type: z.literal(AnnotationLinkTypesSchema.enum.internalLink),
  reference: z.object({ slug: SanitySlugSchema }).nullable(),
})

const AnnotationLinksSchema = z.discriminatedUnion('_type', [PathLinksSchema, ReferenceLinkSchema])

export const annotationFields = groq`
  markDefs[] {
    ${basicFields}
    _type in ['${AnnotationLinkTypesSchema.enum.link}', '${AnnotationLinkTypesSchema.enum.internalPathLink}'] => {
      blank,
      href,
    },
    _type == '${AnnotationLinkTypesSchema.enum.internalLink}' => {
      "reference": reference->{slug}
    },
  },
`

export const AnnotationFieldsSchema = z.object({
  markDefs: z.array(AnnotationLinksSchema),
})
