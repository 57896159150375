import { env } from '@/env.mjs'

import { postJSONRequest } from './postJSONRequest'

export const sendInquiry = async (data: Record<string, unknown>) => {
  const endpointUrl = env.NEXT_PUBLIC_INQUIRY_ENDPOINT

  return postJSONRequest({ data, endpointUrl, context: 'Send inquiry' }).then((result) => ({
    error: result?.error,
  }))
}
