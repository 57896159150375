import * as Sentry from '@sentry/nextjs'
import { DzColumn } from '@zwirner/design-system'
import type { PropsWithChildren } from 'react'

export const ErrorBoundary = ({ children }: PropsWithChildren) => {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <DzColumn className="mb-12 h-full" span={12}>
          <div className="flex justify-center p-5">Something went wrong</div>
        </DzColumn>
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
