import { z } from 'zod'

export const SanityImageSchema = z.object({
  asset: z
    .object({
      _ref: z.string(),
      _type: z.literal('reference'),
    })
    .nullish(),
  _type: z.literal('image'),
})

export const SanitySlugSchema = z.object({
  current: z.string(),
})

export type SanityImageSchemaType = z.infer<typeof SanityImageSchema>
