'use client'

// import {useCart} from '@shopify/hydrogen-react'
import { DzFooter, DzGridColumns, DzHeader } from '@zwirner/design-system'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { type ReactNode, useEffect } from 'react'

import { REVIEW_LINK_TOKEN_COOKIE } from '@/common/constants/api'
// remove Shopify Hydrogen
// import CartPanel from '@/components/containers/cartPanel'
import LinkClickListener from '@/components/containers/LinkClickListener/LinkClickListener'
import type { LayoutProps } from '@/sanity/services/common/getCommonData'
import { deleteCookie } from '@/utils/cookies/deleteCookie'

import { openNewsletterFooter, openNewsletterHeader, PopUps } from '../popups'
import { getFooterProps, getHeaderProps } from './mappers'

export type DefaultLayoutProps = {
  children: ReactNode
  pageType?: string
  layoutData: LayoutProps['layoutData']
  draftMode?: boolean
}

type DefaultLayoutComponent = (props: DefaultLayoutProps) => ReactNode

const Layout: DefaultLayoutComponent = ({ children, pageType, layoutData, draftMode }) => {
  const router = useRouter()
  // remove Shopify Hydrogen
  // const {totalQuantity} = useCart()

  const { menu } = getHeaderProps(layoutData)
  const { data } = getFooterProps(layoutData.footerData)

  useEffect(() => {
    if (!draftMode) {
      deleteCookie(REVIEW_LINK_TOKEN_COOKIE)
    }
  }, [draftMode])

  return (
    <>
      <DzHeader
        menu={menu}
        // TODO: find a way to share types with des-sys
        footerData={data as any}
        headerClass="sticky top-0"
        linkProps={{
          router,
          useRoute: true,
        }}
        LinkElement={Link}
        newsletterAction={openNewsletterHeader}
        collections={undefined}
        // Once we move the shopify icon to the nav we need this
        hasCart={false}
      />

      <PopUps router={router} pageType={pageType} />

      <main className="bg-white-100 px-5 min-app-h" aria-label="Main" role="main">
        <DzGridColumns className="w-full">{children}</DzGridColumns>
      </main>
      <LinkClickListener />
      {/* remove Shopify Hydrogen */}
      {/* <CartPanel /> */}
      <DzFooter
        footerClass="relative bottom-0"
        // TODO: find a way to share types with des-sys
        data={data as any}
        newsletterAction={openNewsletterFooter}
        LinkElement={Link}
      />
    </>
  )
}

export default Layout
