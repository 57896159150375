import { GTMPopupClickText, GTMPopupViewText } from '@/common/constants/gtmConstants'
import { gtmEvent } from '@/common/utils/gtm/gtmEvent'
import type { MethodTypes } from '@/events/ModalTriggerEvent'

export enum TypeTypes {
  FORM = 'form',
  NON_FORM = 'non-form',
}

type ViewedEventData = {
  cta_value: string
  method: MethodTypes
  type: TypeTypes
}

type ClickedEventData = {
  link_url: string
} & ViewedEventData

export const gtmPopupViewedEvent = (event_data: ViewedEventData) => {
  gtmEvent(GTMPopupViewText.event, {
    ...GTMPopupViewText,
    event_data,
  })
}

export const gtmPopupClickedEvent = (event_data: ClickedEventData) => {
  gtmEvent(GTMPopupClickText.event, {
    ...GTMPopupClickText,
    event_data,
  })
}
