import { SUBSCRIBE_TYPE } from '@/common/constants/subscribe'
import {
  gtmRegisteredInterestStartedEvent,
  gtmRegisteredInterestSubmittedEvent,
  gtmRegisteredInterestViewEvent,
} from '@/common/utils/gtm/gtmRegisteredInterestEvent'
import useRegisteredInterestStore from '@/store/registeredInterestStore'

const useGtmRegisteredInterestEvent = () => {
  const method = useRegisteredInterestStore((state) => state.method)
  const cta_value = useRegisteredInterestStore((state) => state.cta_value)
  const setRegisteredInterestState = useRegisteredInterestStore(
    (state) => state.setRegisteredInterestState
  )
  return {
    gtmRegisteredInterestViewEvent: (eventObject: any) => {
      setRegisteredInterestState(eventObject)
      gtmRegisteredInterestViewEvent(eventObject)
    },
    gtmRegisteredInterestStartedEvent: () => {
      gtmRegisteredInterestStartedEvent({
        method,
        cta_value,
      })
    },
    gtmRegisteredInterestSubmittedEvent: (data: any) => {
      let type: Array<string> = []
      if (data.news) type.push(SUBSCRIBE_TYPE.NEWS)
      if (data.access) type.push(SUBSCRIBE_TYPE.ACCESS)
      if (data.events) type.push(SUBSCRIBE_TYPE.EVENTS)
      if (data.news && data.access && data.events) {
        type = [SUBSCRIBE_TYPE.NEWS, SUBSCRIBE_TYPE.ACCESS, SUBSCRIBE_TYPE.EVENTS]
      }
      gtmRegisteredInterestSubmittedEvent(
        { method, cta_value, type: type.toString() },
        { event_form_hashemail: btoa(data?.email) }
      )
    },
  }
}
export default useGtmRegisteredInterestEvent
