import { useEffect, useRef } from 'react'

export const TrustArcBanner = () => {
  const ref = useRef<HTMLSpanElement>(null)
  useEffect(() => {
    const node = ref.current!
    const observer = new MutationObserver((_mutationList, _observer) => {
      if (node.childElementCount > 2) {
        node.replaceChildren(node.children[0], node.children[1])
      }
    })
    observer.observe(node, {
      childList: true,
    })
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <span
      ref={ref}
      id="consent-banner"
      style={{
        position: 'fixed',
        bottom: '0',
        zIndex: 99999,
        width: '100%',
      }}
    />
  )
}
