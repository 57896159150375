import type { SanityImageSource } from '@sanity/image-url/lib/types/types'
import type { DefaultSeoProps, NextSeoProps } from 'next-seo'

import { builder } from '@/sanity/imageBuilder'
import type { GeneralSEOSchemaType } from '@/sanity/queries/components/seo/generalSEO'
import type { PageSEOFieldsExtendedType } from '@/sanity/queries/components/seo/pageSEOFields'
import type { BreadcrumbItemSchema } from '@/sanity/types'

import { DEFAULT_OG_IMAGE_DIMS, DEFAULT_SEO_PROPERTIES } from './constants'

export const getOGImageURL = (asset: SanityImageSource) => {
  const imgSrcBuilder = builder
    .image(asset)
    .width(DEFAULT_OG_IMAGE_DIMS.width)
    .height(DEFAULT_OG_IMAGE_DIMS.height)
    .fit('fill')
    .bg('ffffff')
    .crop('entropy')
    .auto('format')
    .url()
  return imgSrcBuilder
}

export const getOGImageObject = (imgSrc: string, alt: string | null | undefined) => {
  const ogImageObject = {
    images: [
      {
        url: imgSrc,
        width: DEFAULT_OG_IMAGE_DIMS.width,
        height: DEFAULT_OG_IMAGE_DIMS.height,
        alt: alt ?? undefined,
        type: 'image/jpeg',
      },
    ],
  }
  return ogImageObject
}

export const defaultSeoMapper = (data: GeneralSEOSchemaType): DefaultSeoProps => {
  const { globalSEODescription, globalSEOImage, globalSEOTitle } = data
  const { alt, asset } = globalSEOImage ?? {}
  const imgSrc = asset ? getOGImageURL(asset) : null
  const title = globalSEOTitle ?? DEFAULT_SEO_PROPERTIES.title
  const titleTemplate = `%s | ${title}`
  const description = globalSEODescription ?? DEFAULT_SEO_PROPERTIES?.description
  const imageOpenGraphObj = imgSrc ? getOGImageObject(imgSrc, alt) : {}
  const defaultSEO = {
    title,
    description,
    titleTemplate,
    defaultTitle: DEFAULT_SEO_PROPERTIES?.defaultTitle,
    openGraph: {
      ...DEFAULT_SEO_PROPERTIES.openGraph,
      title,
      description,
      ...imageOpenGraphObj,
    },
    twitter: DEFAULT_SEO_PROPERTIES.twitter,
  }
  return defaultSEO
}

export const getFullUrl = (path: string | null, defaultDomain = 'https://www.davidzwirner.com') => {
  if (typeof path !== 'string') return
  const isFullUrl = /^(https?:\/\/[^\s/$.?#].[^\s]*)/i.test(path)
  if (isFullUrl) return path
  if (path === '') return defaultDomain // homepage case
  return `${defaultDomain}/${path.replace(/^\//, '')}`
}

export const perPageSeoMapper = (data: PageSEOFieldsExtendedType): NextSeoProps => {
  const {
    title: _title,
    pageTitle,
    metaDescription,
    robotsNoIndex,
    robotsNoFollow,
    imageMeta,
    socialTitle,
    socialDescription,
    canonical,
    titleTemplate: _titleTemplate,
  } = data

  const { asset } = imageMeta ?? {}
  const alt = imageMeta?.alt ?? 'David Zwirner image'
  const imgSrc = asset ? getOGImageURL(asset) : null

  const title = pageTitle || _title ? { title: (pageTitle || _title)?.slice(0, 160) } : {}
  const titleTemplate = _titleTemplate ? { titleTemplate: _titleTemplate } : {}
  const description = metaDescription ? { description: metaDescription } : {}

  const titleOG = socialTitle ? { title: socialTitle } : {}
  const descriptionOG = socialDescription ? { description: socialDescription } : {}

  const noindex = robotsNoIndex ?? false
  const nofollow = robotsNoFollow ?? false

  const imageOpenGraphObj = imgSrc ? getOGImageObject(imgSrc, alt) : {}
  const perpageSEO = {
    ...title,
    ...titleTemplate,
    ...description,
    noindex,
    canonical: getFullUrl(canonical),
    nofollow,
    openGraph: {
      ...titleOG,
      ...descriptionOG,
      ...imageOpenGraphObj,
    },
  }
  return perpageSEO
}

const getSanityImageLink = (image: any) => {
  const { asset } = image ?? {}
  const imgSrc = asset ? getOGImageURL(asset) : ''
  return imgSrc
}

const processImages = (images = []) => {
  return images?.map((image) => getSanityImageLink(image)).filter((value) => !!value)
}

export const articleJSONLDMapper = (data: any, url: string) => {
  const {
    author,
    description,
    images,
    publisherLogo,
    publisherName,
    title,
    _createdAt,
    _updatedAt,
  } = data ?? {}
  const imagesSchema = processImages(images)
  const publisherLogoSchema = getSanityImageLink(publisherLogo)
  const { name } = author ?? {}
  return {
    url,
    title,
    images: imagesSchema,
    datePublished: _createdAt,
    dateModified: _updatedAt,
    authorName: [
      {
        name,
        // url: 'https://example.com',
      },
    ],
    publisherName,
    publisherLogo: publisherLogoSchema,
    description,
    isAccessibleForFree: true,
  }
}

const processBreadcrumbs = (breadcrumbs: BreadcrumbItemSchema[]) =>
  breadcrumbs?.map((breadcrumb, key) => ({
    position: key + 1,
    name: breadcrumb.name,
    item: breadcrumb.item,
  }))

export const breadcrumbsJSONLDMapper = (data: any) => {
  const itemListElements = processBreadcrumbs(data)
  return {
    itemListElements,
  }
}

const processAction = (actions: any) =>
  actions?.map((action: any) => ({ target: action.target, queryInput: 'search_term_string' }))
export const searchBoxJSONLDMapper = (data: any, url: string) => {
  const potentialActions = processAction(data)
  return {
    url,
    potentialActions,
  }
}
