import { envHost } from '@/sanity/env'

export const DEFAULT_SEO_PROPERTIES = {
  title: 'David Zwirner',
  description:
    'David Zwirner is a gallery with locations in New York, London, Paris and Hong Kong. We present physical and online exhibitions as well as podcasts, books, and more.',
  titleTemplate: '%s | David Zwirner',
  defaultTitle: 'David Zwirner',
  languageAlternates: [{ hrefLang: 'en', href: envHost }],
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://www.davidzwirner.com/',
    siteName: 'David Zwirner',
  },
  twitter: {
    site: '@davidzwirner',
    cardType: 'summary_large_image',
  },
}

export const DEFAULT_OG_IMAGE_DIMS = {
  width: 1200,
  height: 630,
}
