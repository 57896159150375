import { groq } from 'next-sanity'
import { z } from 'zod'

import { mediaBuilder, MediaBuilderSchema } from '@/sanity/queries/components/builders/mediaBuilder'

import {
  basicContentWithLinks,
  BasicContentWithLinksSchema,
} from '../components/portableText/basicContentWithLinks'

const PageSectionsTypeSchema = z.enum([
  'article',
  'artistPage',
  'exhibitionPage',
  'exceptionalWork',
  'fairPage',
  'onlineExhibitionPage',
  'availableArtworks',
  'artistListing',
  'exhibitionsLanding',
  'exhibitionsPast',
  'home',
  'page',
  'singlePageRecord',
  'customSlug',
])

const ExhibitionsDateFilterSchema = z.enum(['previous', 'current', 'upcoming'])

const triggerFields = groq`
    timeBased,
    timeBased == true => {
      triggerTime
    },
  `
// scrollBased,
// scrollBased == true => {
//   scrollValue
// }

const TriggerFieldsSchema = z.object({
  timeBased: z.boolean(),
  // scrollBased: z.boolean(),
  triggerTime: z.number().nullish(),
  // scrollValue: z.number().nullish(),
})

export const filterSectionsFields = groq`
  type,
  openStatusFilter,
  type == "singlePageRecord" || type == "page" => {
    page -> {
      _type,
      'url': slug.current,
    },
  },
  type == "customSlug" => {
    'page': {
      '_type': 'customSlug',
      'url': customSlug,
    }
  }
`

export const FilterSectionsSchema = z.array(
  z.object({
    type: PageSectionsTypeSchema,
    openStatusFilter: z.nullable(ExhibitionsDateFilterSchema),
    customSlug: z.string().nullish(),
    page: z
      .object({
        _type: z.string(),
        url: z.string().nullish(),
      })
      .nullish(),
  })
)

const popupFields = groq`
  type,
  name,
  displayAlways,
  _key,
  filterSections[]{
    ${filterSectionsFields}
  },
  termsAndConditions->{
    content[] {
      ${basicContentWithLinks}
    },
    name
  },
  triggers{
    ${triggerFields}
  },
  tags,
  type == "newsletter" || type == "customPromo" => {
    title,
    description,
    type == "newsletter" => {
      'primaryCTA': {
        'action': 'newsletter',
        'text': ctaText
      }
    },
    type == "customPromo" => {
      primaryCTA {
        ...,
        action == "Link Content" => {
          'link': {
            'href': linkedContent->slug.current,
            'blank': false
          }
        }
      }
    },
    media {
      ${mediaBuilder}
    },
  },
  type == "customPromo" => {
    submissionCTA,
  },
`

const commonPopupFields = z.object({
  _key: z.string(),
  name: z.string().nullish(),
  displayAlways: z.nullable(z.boolean()),
  termsAndConditions: z
    .object({
      name: z.string().nullable(),
      content: z.nullable(z.array(BasicContentWithLinksSchema)),
    })
    .nullable(),
  filterSections: FilterSectionsSchema,
  triggers: TriggerFieldsSchema,
  tags: z.array(z.string()).nullish(),
})
const newsletterCustomPromoFields = z.object({
  title: z.string().nullish(),
  description: z.any().nullish(),
  primaryCTA: z.nullable(z.any()),
  media: MediaBuilderSchema.nullish(),
})
export type CustomNewsletterPopupFieldsType = z.infer<typeof newsletterCustomPromoFields>

const customPromoFields = z.object({
  submissionCTA: z.any().nullish(),
})

const PopupItemSchema = z.discriminatedUnion('type', [
  commonPopupFields.merge(newsletterCustomPromoFields).extend({ type: z.literal('newsletter') }),
  commonPopupFields.extend({ type: z.literal('inquire') }),
  commonPopupFields
    .merge(newsletterCustomPromoFields)
    .merge(customPromoFields)
    .extend({ type: z.literal('customPromo') }),
])

export type PopupItemType = z.infer<typeof PopupItemSchema>

export const allCampaigns = groq`
*[_type == "campaign"]{
    _id,
    title,
    orderRank,
    cookieDaysToExpire,
    popupsList[]{
        ${popupFields}
    },
}|order(orderRank)`

export const AllCampaignsSchema = z.array(
  z.object({
    _id: z.string(),
    title: z.string(),
    cookieDaysToExpire: z.number(),
    popupsList: z.nullable(z.array(PopupItemSchema)),
  })
)

export type FilterSectionsType = z.infer<typeof FilterSectionsSchema>
export type AllCampaignsType = z.infer<typeof AllCampaignsSchema>
