import { groq } from 'next-sanity'
import { z } from 'zod'

export const file = groq`
  _type,
  asset->{
    extension,
    _updatedAt,
    path,
    size,
    _createdAt,
    uploadId,
    _type,
    mimeType,
    assetId,
    _rev,
    sha1hash,
    url,
    _id,
    originalFilename,
  }
`

export const FileSchema = z.object({
  _type: z.string(),
  asset: z.object({
    extension: z.string(),
    _updatedAt: z.string(),
    path: z.string(),
    size: z.number(),
    _createdAt: z.string(),
    uploadId: z.string(),
    _type: z.string(),
    mimeType: z.string(),
    assetId: z.string(),
    _rev: z.string(),
    sha1hash: z.string(),
    url: z.string(),
    _id: z.string(),
    originalFilename: z.string(),
  }),
})

export type FileType = z.infer<typeof FileSchema>
