import type { CookieOptions } from './types'

type SetCookieFunc = (
  name: string,
  value: any,
  options?: CookieOptions,
  expirations?: { daysToExpire?: number; minutesToExpire?: number }
) => void

export const setCookie: SetCookieFunc = (name, value, options = {}, expirations = {}) => {
  const { daysToExpire, minutesToExpire } = expirations
  const serializedValue = JSON.stringify(value)
  let cookieString = `${name}=${encodeURIComponent(serializedValue)}`

  if (options.expires) {
    // delete cookie case
    const expirationDate = options.expires

    if (!isNaN(expirationDate.getTime())) {
      cookieString += `; expires=${expirationDate.toUTCString()}`
    }
  } else if (daysToExpire) {
    // displayAlways false case
    const expirationDate = new Date()
    expirationDate.setDate(expirationDate.getDate() + daysToExpire)

    cookieString += `; expires=${expirationDate.toUTCString()}`
  } else if (minutesToExpire) {
    // displayAlways true case
    const expirationDate = new Date()
    expirationDate.setMinutes(expirationDate.getMinutes() + minutesToExpire)

    cookieString += `; expires=${expirationDate.toUTCString()}`
  }

  if (options.path) {
    cookieString += `; path=${options.path}`
  }

  if (options.domain) {
    cookieString += `; domain=${options.domain}`
  }

  if (options.secure) {
    cookieString += '; secure'
  }

  if (options.httpOnly) {
    cookieString += '; HttpOnly'
  }

  document.cookie = cookieString
}
