import imageUrlBuilder from '@sanity/image-url'

import { getClient } from '@/sanity/client'

const { client } = getClient({
  draftMode: false,
  draftViewToken: '',
  originName: 'service-imageUrlBuilder',
  filePath: __filename,
})
export const builder = imageUrlBuilder(client)
