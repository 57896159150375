export enum ComponentType {
  PRODUCT = 'product',
  COLLECTION = 'collection',
  PRODUCT_SET = 'productSet',
  CART = 'cart',
}

export type ShopifyComponent = {
  productId: string
  nodeId: string
  componentType: ComponentType
  text: string | undefined
  variant: ComponentVariant
}

export enum ComponentVariant {
  PRIMARY_BUTTON = 'primaryButton',
  SECONDARY_BUTTON = 'secondaryButton',
}

type Options = {
  text?: {
    button?: string | undefined
  }
}

type VariantProps = {
  styleVariant: ComponentVariant
  options?: Options
}

const brandFontFamily = 'Avenir, Roboto, Helvetica Neue, Helvetica, san-serif'

// default options https://github.com/Shopify/buy-button-js/blob/master/src/defaults/components.js
const commonOptions = {
  product: {
    styles: {},
    contents: {
      img: false,
      title: false,
      price: false,
    },
    text: {
      button: 'Add to cart',
      outOfStock: 'Out of stock',
    },
  },
  productSet: {
    styles: {
      products: {
        '@media (min-width: 601px)': {
          'margin-left': '-20px',
        },
      },
    },
  },
  modalProduct: {
    styles: {},
    contents: {
      img: false,
      imgWithCarousel: false,
      button: false,
      buttonWithQuantity: false,
    },
    text: {
      button: 'Add to cart',
    },
  },
  option: {},
  cart: {
    styles: {
      title: {
        color: '#000',
        'font-size': '1.57143rem',
        'font-family': brandFontFamily,
        'font-weight': 500,
      },
      header: {
        color: '#7e7979',
      },
      lineItems: {
        color: '#000',
        'font-family': brandFontFamily,
        'font-weight': 500,
      },
      subtotalText: {
        color: '#757575',
        'font-size': '1rem',
        'font-weight': 500,
        'text-transform': 'capitalize',
      },
      subtotal: {
        color: '#000',
        'font-family': brandFontFamily,
        'font-weight': 500,
      },
      // if we just hide notice block, then the clearing styles (clear: both) will not be applied,
      // and the space between the content and the button in the footer will collapse
      // so the notice text (a little below) is set to an empty string
      // and the top indent is reset
      notice: {
        'padding-top': 0,
      },
      currency: {
        color: '#7e7979',
      },
      close: {
        color: '#7e7979',
        'font-size': '2.38rem',
        ':hover': {
          color: '#7e7979',
        },
      },
      empty: {
        color: '#7e7979',
      },
      noteDescription: {
        color: '#7e7979',
      },
      discountText: {
        color: '#7e7979',
      },
      discountIcon: {
        fill: '#7e7979',
      },
      discountAmount: {
        color: '#7e7979',
      },
    },
    text: {
      total: 'Subtotal',
      button: 'Checkout',
      notice: '',
    },
    popup: false,
    events: {
      afterRender: function (component: { node: HTMLElement }) {
        unsetFocusFromCloseButton(component.node)
      },
    },
  },
  toggle: {
    sticky: true,
    contents: {
      count: true,
    },
    templates: {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.90514 7.33628C7.90514 7.71832 7.61019 8.14435 7.21361 8.14435C6.81703 8.14435 6.51988 7.71832 6.51988 7.33628C6.51988 7.00287 6.55523 6.84079 6.60162 6.63704C6.64912 6.42981 6.70657 6.17165 6.75849 5.60322C6.76732 5.5025 6.77616 5.38673 6.78279 5.26402L6.78942 5.17835C6.82477 4.61803 6.84686 3.93383 6.85901 3.08756C6.85901 1.37649 8.17136 0 9.78529 0C11.3572 0 12.6099 1.26072 12.7049 2.8977H12.7083L12.7138 3.06556C12.7237 3.89215 12.7469 4.60413 12.7834 5.17835C12.7944 5.33695 12.8055 5.47703 12.8165 5.60322C12.8637 6.13375 12.9203 6.39364 12.965 6.59904L12.969 6.61736C13.0154 6.8269 13.0518 6.99476 13.0518 7.33628C13.0518 7.71832 12.7558 8.14435 12.3592 8.14435C11.9626 8.14435 11.6677 7.71832 11.6677 7.33628C11.6677 7.00287 11.7019 6.84195 11.7483 6.63704C11.7958 6.43097 11.8532 6.17165 11.9052 5.60322C11.9162 5.47935 11.9273 5.33579 11.9361 5.17951C11.9725 4.62729 11.9935 3.94309 12.0068 3.08872C12.0035 1.79095 11.0082 0.733976 9.78529 0.733976C8.56352 0.733976 7.56711 1.79095 7.5649 3.09103C7.57815 3.95583 7.60135 4.64002 7.63781 5.17835C7.64775 5.33811 7.65769 5.47819 7.66874 5.60322C7.71845 6.14965 7.77589 6.40897 7.82229 6.61736C7.86868 6.8269 7.90514 6.99476 7.90514 7.33628ZM8.21451 5.47955H11.2214L11.3706 5.47144L11.3838 5.31631L11.3874 5.27598C11.3976 5.16222 11.4091 5.03514 11.417 4.89144L11.428 4.70968H8.15154L8.1648 4.8926C8.17109 4.98366 8.17811 5.06795 8.18461 5.14612L8.18461 5.14615C8.18952 5.20511 8.19414 5.26058 8.19794 5.31284L8.19904 5.32326L8.21451 5.47955ZM13.2715 4.70971L13.2848 4.89262C13.2939 5.04264 13.3059 5.17603 13.3163 5.2928L13.3163 5.29282L13.319 5.32329L13.3334 5.47957H14.5176V17.2301H4.7335V5.47957H6.24249L6.25574 5.32329C6.2679 5.19941 6.27894 5.05586 6.28999 4.89262L6.30214 4.70971H4V18H15.25V4.70971H13.2715Z" fill="black"/>
    </svg>`,
      count: `<span class="{{data.classes.toggle.count}}">{{data.count}}</span>`,
    },
    styles: {
      toggle: {
        ':hover': {
          'background-color': '#FFFFFF',
          'border-color': '#000000',
          color: '#000000',
        },
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'min-width': '60px',
        height: '60px',
        padding: 0,
        gap: '5px',
        'background-color': '#FFFFFF',
        'max-width': 'fit-content',
        'flex-direction': 'row-reverse',
        'font-family': 'var(--font-sans)',
        'border-width': '1px',
        'border-color': '#CDCDCD',
        color: '#4D4D4D',
      },
      count: {
        'font-family': brandFontFamily,
        'font-size': '1rem',
        'line-height': '1.5rem',
        color: '#000000',
        'margin-left': '0px !important',
        'margin-bottom': '0px',
      },
    },
  },
  lineItem: {
    contents: {
      image: true,
      variantTitle: true,
      title: true,
      price: true,
      compareAtPrice: true,
      priceWithDiscounts: false,
      quantity: true,
      quantityIncrement: true,
      quantityDecrement: true,
      quantityInput: true,
    },
    templates: {
      price: `<span class="{{data.classes.lineItem.price}}" data-element="lineItem.price">{{data.formattedPrice}}</span>`,
    },
    styles: {
      variantTitle: {
        color: '#7e7979',
      },
      title: {
        color: '#7e7979',
      },
      price: {
        color: '#000',
        'font-weight': '500',
      },
      fullPrice: {
        color: '#7e7979',
      },
      discount: {
        color: '#7e7979',
      },
      discountIcon: {
        fill: '#7e7979',
      },
      quantity: {
        color: '#7e7979',
      },
      quantityIncrement: {
        color: '#7e7979',
        'border-color': '#7e7979',
      },
      quantityDecrement: {
        color: '#7e7979',
        'border-color': '#7e7979',
      },
      quantityInput: {
        color: '#7e7979',
        'border-color': '#7e7979',
      },
    },
  },
}

// prettier-ignore
const sharedButtonStyles = {
  ':disabled': {
    'background-color': '#CDCDCD',
    'border-color': '#CDCDCD',
    'color': '#FFFFFF',
    'pointer-events': 'none',
  },
  'font-family': brandFontFamily,
  'outline-color': 'transparent',
  '-webkit-user-select': 'none',
  '-moz-user-select': 'none',
  'user-select': 'none',
  'background-color': 'transparent',
  'max-width': '100%',
  'min-width': 'fit-content',
  'padding-top': '13px',
  'padding-bottom': '13px',
  'padding-left': '25px',
  'padding-right': '25px',
  'font-size': '16px',
  'font-weight': '500',
  'line-height': '24px',
  'margin-top': '20px',
  'border-radius': '0',
  'width': '100%',
  'appearance': 'button',
  'display': 'block',
  'letter-spacing': 'normal',
}

// prettier-ignore
export const styleVariants: Record<ComponentVariant, any> = {
  primaryButton: {
    button: {
      ':active': {
        'background-color': 'transparent',
        'border-color': '#000000',
        'color': '#000000',
      },
      ':focus': {
        'background-color': 'transparent',
        'border-color': '#000000',
        'color': '#000000',
      },
      ':hover': {
        'background-color': 'transparent',
        'border-color': '#000000',
        'color': '#000000',
      },
      'border-style': 'solid',
      'border-width': '1px',
      'border-color': '#CDCDCD',
      'color': '#4D4D4D',
      ...sharedButtonStyles,
    },
  },
  secondaryButton: {
    button: {
      ':active': {
        'background-color': 'transparent',
        'color': '#4D4D4D',
      },

      ':focus': {
        'background-color': 'transparent',
        'color': '#4D4D4D',
      },
      ':hover': {
        'background-color': 'transparent',
        'color': '#4D4D4D',
      },
      'color': '#757575',
      ...sharedButtonStyles,
    },
  },
}

export const getVariantProps = ({ styleVariant, options }: VariantProps) => {
  const variantStyle = styleVariants[styleVariant]
  return {
    product: {
      ...commonOptions.product,
      styles: { ...variantStyle },
      text: { ...commonOptions.product.text, ...options?.text },
    },
    productSet: {
      ...commonOptions.productSet,
      styles: { ...commonOptions.productSet.styles, ...variantStyle },
    },
    modalProduct: {
      ...commonOptions.modalProduct,
      styles: { ...commonOptions.modalProduct.styles, ...variantStyle },
    },
    option: { ...commonOptions.option, styles: { ...variantStyle } },
    cart: {
      ...commonOptions.cart,
      styles: { ...commonOptions.cart.styles, ...variantStyle },
    },
    toggle: {
      ...commonOptions.toggle,
      styles: { ...commonOptions.toggle.styles, ...variantStyle },
    },
    lineItem: {
      ...commonOptions.lineItem,
      styles: { ...commonOptions.lineItem.styles, ...variantStyle },
    },
  }
}

function unsetFocusFromCloseButton(node: HTMLElement) {
  const iframe = node.querySelector('[name="frame-cart"]') as HTMLIFrameElement
  const closeButton = iframe?.contentWindow?.document.querySelector(
    '[data-element="cart.close"]'
  ) as HTMLButtonElement
  if (closeButton) {
    setTimeout(() => {
      closeButton.blur()
    }, 100)
  }
}
