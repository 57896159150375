import { groq } from 'next-sanity'
import { z } from 'zod'

import { basicFields } from './primitives'

export const BasicFieldsSchema = z.object({
  _key: z.string(),
  _type: z.literal('block'),
})

export const basicBlockFields = groq`
  ${basicFields}
  style,
  children[] {
    ${basicFields}
    marks[],
    text,
  },
`

export const basicContent = groq`
  ${basicBlockFields}
  "style": "normal",
`

export const ChildrenSchema = z.object({
  _key: z.string(),
  _type: z.literal('span'),
  marks: z.array(z.string()),
  text: z.string(),
})

export const BasicContentSchema = BasicFieldsSchema.extend({
  style: z.literal('normal'),
  children: z.array(ChildrenSchema),
})

export type BasicContentType = z.infer<typeof BasicContentSchema>
