export const SUBSCRIBE_TYPE = {
  NEWS: 'news',
  ACCESS: 'sales',
  EVENTS: 'books',
} as const

export const SUBSCRIBE_METHOD = {
  FOOTER: 'footer',
  POPUP: 'popup',
  INTERSTITIAL: 'interstitial',
  NAV: 'nav',
} as const

export type SubscribeMethod = (typeof SUBSCRIBE_METHOD)[keyof typeof SUBSCRIBE_METHOD]
