import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import { GTMScrollDepths } from '@/common/constants/gtmConstants'
import { gtmEvent } from '@/common/utils/gtm/gtmEvent'

const NUMBER_OF_DECIMALS = 3
const MIN_PERCENTAGE = 1
const VERTICAL_SCROLL_DEPTHS_PERCENTAGES_THRESHOLD = [10, 20, 30, 40, 50, 60, 70, 80, 90]

enum ScrollUnits {
  'percent' = 'percent',
  'pixel' = 'pixel',
}
enum ScrollDirection {
  'vertical' = 'vertical',
  'horizontal' = 'horizontal',
}

type UseScrollDepthEventProps = {
  percentages?: Array<number>
}

const useScrollDepthEvent = ({
  percentages = VERTICAL_SCROLL_DEPTHS_PERCENTAGES_THRESHOLD,
}: UseScrollDepthEventProps) => {
  const router = useRouter()
  const maxPercentage = useRef(0)
  const maxPixels = useRef(0)

  const alreadyCheckedThresholds = useRef(percentages)

  useEffect(() => {
    const handleRouteChange = () => {
      const percentageWithDefault = percentages.length
        ? percentages
        : VERTICAL_SCROLL_DEPTHS_PERCENTAGES_THRESHOLD
      maxPercentage.current = 0
      maxPixels.current = 0
      alreadyCheckedThresholds.current = percentageWithDefault
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events, percentages])

  useEffect(() => {
    function handleScroll() {
      const publishGTMScroll = (
        threshold: number,
        scrollUnits: ScrollUnits,
        scrollDirection: ScrollDirection
      ) => {
        const gtmData = {
          detailed_event: GTMScrollDepths.detailed_event,
          event: GTMScrollDepths.event,
          event_data: { scrollThreshold: threshold, scrollUnits, scrollDirection },
        }
        gtmEvent('Scroll Depth', gtmData)
      }

      const lastPercentage = Number.parseFloat(
        (
          Math.min(
            MIN_PERCENTAGE,
            (window.innerHeight + window.scrollY) / document.body.offsetHeight
          ) * 100
        ).toFixed(NUMBER_OF_DECIMALS)
      )
      const lastPixels = window.innerHeight + window.scrollY

      if (lastPercentage > maxPercentage.current) {
        maxPercentage.current = lastPercentage

        const threshold = alreadyCheckedThresholds.current.find((x) => lastPercentage >= x)

        if (threshold !== undefined) {
          const index = alreadyCheckedThresholds.current.indexOf(threshold)
          if (index > -1) {
            const listThresholds = [...alreadyCheckedThresholds.current]
            listThresholds.splice(index, 1)
            alreadyCheckedThresholds.current = listThresholds
          }
          publishGTMScroll(threshold, ScrollUnits.percent, ScrollDirection.vertical)
        }
      }

      if (lastPixels > maxPixels.current) {
        maxPixels.current = lastPixels
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [alreadyCheckedThresholds])

  return { maxPercentage, maxPixels }
}

export default useScrollDepthEvent
