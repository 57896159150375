import * as Sentry from '@sentry/nextjs'

import { getClient } from '@/sanity/client'
import type { AllEmailGatesType, EmailGateType } from '@/sanity/queries/emailGates/allEmailGates'
import { AllEmailGatesScheme } from '@/sanity/queries/emailGates/allEmailGates'

import { PageSections } from '../popups/constants'
import { LandingTypeToURL } from '../popups/getAllCampaigns'

export type EmailGateData = Omit<EmailGateType, 'slugs' | 'filterSections'>

export type EmailGateDataDictionary = Record<string, EmailGateData>

export async function getAllEmailGates(query: string) {
  const { client } = getClient({
    draftMode: false,
    draftViewToken: '',
    originName: 'service-getAllEmailGates',
    filePath: __filename,
  })
  const data = await client.fetch(query)
  const { data: validatedData, success, error } = AllEmailGatesScheme.safeParse(data)
  if (!success) {
    Sentry.captureException(error)
    return {}
  }
  return getAllEmailGatesDataNormalized(validatedData)
}

function getAllEmailGatesDataNormalized(data: AllEmailGatesType): EmailGateDataDictionary {
  return data.reduce<EmailGateDataDictionary>((dictionary, item) => {
    const { filterSections, ...rest } = item
    filterSections?.forEach((section) => {
      const { page, type } = section
      const key = getEmailGateDictionaryKey(page, type)
      if (key) {
        dictionary[key] = rest
      }
    })
    return dictionary
  }, {})
}

function getEmailGateDictionaryKey(
  page?: { url?: string | null } | null | undefined,
  type?: string
) {
  const { url } = page ?? {}
  const sectionKey = Object.entries(PageSections).find(([_, value]) => value === type)?.[0]
  if (
    (type === PageSections['Single Page Record'] || type === PageSections['Special Pages']) &&
    url
  ) {
    return url
  } else if (type && LandingTypeToURL[type as keyof typeof LandingTypeToURL]) {
    return LandingTypeToURL[type as keyof typeof LandingTypeToURL]
  } else if (type === 'customSlug' && url) {
    return url
  } else if (sectionKey) {
    return sectionKey
  }
}
